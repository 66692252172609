import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

/**
 * dgxClickOutside is an Angular directive which emits an event (clickOutside) when a user clicks outside of a target element.
 * Monitors any DOM click event outside of the target element.
 *
 *  <div (clickOutside)="closeDropdown()" dgxClickOutside></div>
 *
 * @see dgxCombobox component for further reference.
 *
 * (Ex. Attach an event listener when a user closes a dropdown.)
 */
@Directive({
  selector: '[dgxClickOutside]',
  standalone: true,
})
export class ClickOutsideDirective {
  @Output() public clickOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }
}
