<div
  class="reactive-form__group"
  [formGroup]="advancedSettingsForm"
  formGroupName="advancedSettings"
>
  <div class="guts-m-t-2 df-form__label">
    <div class="l_flexbar">
      <strong class="color-blue">{{ i18n.Core_AdvancedSettings }}</strong>
      <button
        df-button
        df-button-square
        dfButtonType="secondary"
        class="advanced-settings__expander"
        (click)="toggleExpanded()"
        [attr.aria-expanded]="expanded"
        aria-controls="advancedSettings"
        data-dgat="advanced-settings-45f"
        [attr.aria-label]="i18n.Core_AdvancedSettings"
      >
        <df-icon
          [icon]="expanded ? 'chevron-up' : 'chevron-down'"
          [a11yText]="i18n.Core_AdvancedSettings"
        ></df-icon>
      </button>
    </div>
  </div>

  <div *ngIf="expanded" [@dfCollapseExpand]>
    <!-- Visibility -->
    <ng-container *ngIf="advancedSettingsForm.get('visibility')">
      <div
        class="reactive-forms__control guts-m-t-1-half"
        [class.ng-invalid]="isFieldInvalid('visibility.groups')"
      >
        <dgx-visibility
          [options]="visibilityField.options"
          [groups]="visibilityField.groups"
          [selected]="visibilityField.selectedOption"
          [groupPrivacyLevel]="visibilityField.groupPrivacyLevel"
          [excludedPrivacyLevels]="excludedGroupPrivacyLevels"
          labelText="orgInternalContentVisibilitySelector_Title"
          (onSelection)="onVisibilitySelection($event)"
          (onGroupSelection)="onGroupSelection($event)"
          (onGroupRemove)="onGroupRemove($event)"
          (onGroupInputBlur)="onBlur('visibility.groups')"
        ></dgx-visibility>
        <dgx-validate-field
          *ngIf="isFieldInvalid('visibility.groups')"
          [message]="i18n.Core_FieldRequired"
        ></dgx-validate-field>
      </div>
    </ng-container>

    <!-- Published Date -->
    <ng-container *ngIf="advancedSettingsForm.get('publishedDate')">
      <div class="reactive-forms__control">
        <dgx-reactive-forms-label for="dgxDatePicker">
          {{ i18n.dgOrgInternalContentForm_CreationDate }}
        </dgx-reactive-forms-label>
        <dgx-date-picker
          id="publishedDate"
          [isMaxDateToday]="true"
          [date]="publishDateField?.date"
          [placeholder]="i18n.Core_SelectDate"
          (onBlur)="onBlur('publishedDate')"
          (dateSelect)="onDateSelected($event)"
        ></dgx-date-picker>
        <dgx-validate-field
          class="block"
          *ngIf="
            advancedSettingsForm.get('publishedDate').touched &&
            advancedSettingsForm.get('publishedDate').invalid
          "
          [message]="i18n.MediaFormCtrl_TitleRequired"
        ></dgx-validate-field>
      </div>
    </ng-container>

    <!-- Internal Item Id -->
    <ng-container *ngIf="advancedSettingsForm.get('internalItemId')">
      <div
        class="reactive-forms__control"
        [class.reactive-forms__read-only]="!!formModel.isFileManaged"
      >
        <dgx-reactive-forms-label for="internalItemId">
          {{ i18n.dgOrgInternalContentForm_InternalItemId }}
        </dgx-reactive-forms-label>
        <input
          type="text"
          id="internalItemId"
          formControlName="internalItemId"
          [placeholder]="internalItemIdPlaceholder"
          (blur)="onBlur('internalItemId')"
          [readonly]="!!formModel.isFileManaged"
          [attr.aria-disabled]="!!formModel.isFileManaged"
          data-dgat="advanced-settings-819"
        />
        <dgx-validate-field
          class="block"
          *ngIf="
            advancedSettingsForm.get('internalItemId').touched &&
            advancedSettingsForm.get('internalItemId').invalid
          "
          [message]="i18n.MediaFormCtrl_TitleRequired"
        ></dgx-validate-field>
      </div>
    </ng-container>

    <!-- Language -->
    <ng-container *ngIf="advancedSettingsForm.get('language')">
      <div class="reactive-forms__control">
        <dgx-reactive-forms-label for="language">
          {{ i18n.dgOrgInternalContentForm_Languages }}
        </dgx-reactive-forms-label>
        <dgx-combobox
          id="language"
          class="full-width"
          [hasNoResultsText]="i18n.Core_NoResultsFound"
          [labelKey]="languageField.labelKey"
          [options]="languageField.options"
          [placeholder]="i18n.dgOrgInternalContentForm_SelectLanguage"
          [selectedOption]="languageField?.selectedOption"
          [ariaLabel]="i18n.dgOrgInternalContentForm_Languages"
          (selection)="onLanguageSelection($event)"
          (onBlur)="onBlur('language')"
          dgatInput="advanced-settings-d00"
        ></dgx-combobox>
      </div>
    </ng-container>
  </div>
</div>
