<ng-container
  *ngFor="let predicate of predicates; let first = first; trackBy: trackById"
  data-dgat="predicate-list-eca"
>
  <strong *ngIf="!first"> {{ i18n.BusinessRules_And | uppercase }} </strong>

  {{ predicate.fieldName }}
  {{ predicate.comparisonOperator }}
  {{ predicate.comparisonValue?.[0] }}
</ng-container>
