<ng-container *ngIf="useApollo() && currentRoute === 'branding'; else v1">
  <dgx-org-branding-v3></dgx-org-branding-v3>
</ng-container>

<ng-template #v1>
  <dgx-tab-navigation
    [tabList]="tabs"
    *ngIf="showNavigation && !useApollo()"
  ></dgx-tab-navigation>
</ng-template>

<router-outlet (activate)="onActivate($event)"></router-outlet>
