import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ProfilePicModule } from '@app/shared/components/profile-pic/profile-pic.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'dgx-data-column-person',
  templateUrl: './data-column-person.component.html',
  styleUrls: ['./data-column-person.component.css'],
  standalone: true,
  imports: [ProfilePicModule, CommonModule, TranslateModule],
})
export class DataColumnPersonComponent implements OnInit {
  // Bindings - Input
  @Input() public propIsAdmin: string;
  @Input() public propHref: string;
  @Input() public propEmail: string;
  @Input() public propName: string;
  @Input() public propPic: string;
  @Input() public picHideMentorIcon: boolean;
  @Input() public picMentorIconSize: string;
  @Input() public person: any;
  @Input() public profile;
  @Input() public picFallback: string;
  @Input() public picSize: string;
  @Input() public picUseAlt: boolean;
  // Local
  public privacyId: number;

  // Angular methods
  public ngOnInit(): void {
    // defaults -- the dataTable will pass in undefined if they're left blank
    // so we must manually override them rather than relying on normal optional
    // @input() indicators.
    this.picSize = this?.picSize || 'small';
    // create profile if not provided
    if (!this.profile) {
      this.profile = {
        name: this.person[this.propName],
        picture: this.propPic ? this.person[this.propPic] : '',
        email: this.person[this.propEmail],
        isEngaged: this.person.isEngaged,
        isMentoring: !!this.person.isMentoring,
      };
    }
  }
}
