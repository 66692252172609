import {
  CdkVirtualScrollViewport,
  ScrollingModule,
} from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { fadeInAndOut } from '@app/shared/animations/animations';
import { sortBy } from '@app/shared/utils/common-utils';
import { getDeepCopy } from '@app/shared/utils/property';
import { WindowToken } from '@app/shared/window.token';
import {
  DfButtonBasicModule,
  DfIconChevronDown12,
  DfIconModule,
  DfIconRegistry,
  DfInputDecoratorModule,
} from '@lib/fresco';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { ComboboxOption } from './combobox.model';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToggleSwitchComponent } from '../toggle-switch/toggle-switch.component';
import { ClickOutsideDirective } from '@app/shared/directives/click-outside.directive';
import { DgxItemRoleModule } from '@app/shared/directives/item-role/item-role.module';

/**
 * NOTE: Module-scoped variable, to support assigning a unique id to each instance of a `dgxSelect` that does not
 * explicity have an id set on the component. This id value is used in the aria attributes of the component
 */
let nextId = 1;

/**
 * @name Combobox Component
 *
 * @desc Simple search box with a dropdown list and uses custom typehead functionality
 * Combobox can handle options of type array of objects and can easily be refactored to handle other cases
 *
 * @param {string} ariaLabel - aria-label for the combobox input
 * @param {string} hasNoResultsText - message to display when no options are found by filtering
 * @param {string} loadingText - message to display when options are loading
 * @param {boolean} isExactMatchesEnabled [false] - show or hide the exact match toggle switch
 * @param {boolean} isMultiSelect [false] - whether or not to allow multiple options to be selected
 * @param {string} labelKey - Key for the property to iterate on in the object in the array of options
 * @param {string} trackBy [id] - Key for the trackBY function, this speeds up the search for long lists and defaults to 'id' prop
 * @param {Array<T>} options - Input options in a form of array of objects whose keys align with labelKey and trackBy.
 * @param {string} placeholder - Translated string
 * @param {string} selectedOption - Current selected option, must be the same type as the labelKey prop
 * @param {Array<T>} selectedOptions - Current selected options if isMultiSelect is true
 * @param {Function} selection - Handle selection output
 * @param {Function} selections - Handle multiple selections output if isMultiSelect is true
 * @param {string} dgatInput - dgat to pass onto inner button. Falls back to 'combobox'.
 *
 *
 * @example
 * <dgx-combobox
 *  [ariaLabel]="ariaLabelText"
 *  [hasNoResultsText]="hasNoResultsText"
 *  [loadingText]="loadingText"
 *  [isExactMatchesEnabled]="true"
 *  [isMultiSelect]="true"
 *  [labelKey]="propName"
 *  [options]="optionList"
 *  [placeholder]="placeholderText"
 *  [trackBy]="trackByProp"
 *  [selectedOption]="selectedOption"
 *  [selectedOptions]="selectedOptions"
 *  (selection)="handleSelection($event)"
 *  (selections)="handleSelections($event)"
 *  dgatInput="my-component-xxx">
 * </dgx-combobox>
 *
 */

/**
 * TODO: Handle handleKeyup event for multi select
 */
@Component({
  selector: 'dgx-combobox',
  templateUrl: './combobox.component.html',
  styleUrls: ['./combobox.component.scss'],
  animations: [fadeInAndOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DfButtonBasicModule,
    DfIconModule,
    DfInputDecoratorModule,
    ToggleSwitchComponent,
    ScrollingModule,
    DgxItemRoleModule,
    ClickOutsideDirective,
  ],
})
export class ComboboxComponent implements OnInit, AfterViewInit, OnChanges {
  // Bindings - input
  @Input() public ariaLabel: string;
  @Input() public hasNoResultsText: string;
  @Input() public loadingText?: string;
  @Input() public isExactMatchesEnabled: boolean = false;
  @Input() public labelKey: string;
  @Input() public options: ComboboxOption[];
  @Input() public getOptions: () => ComboboxOption[] = null;
  @Input() public placeholder: string;
  /** Disables the combobox */
  @Input() public disabled: boolean = false;
  @Input() public readonly: boolean = false;
  @Input() public trackBy: string = 'id';
  @Input() public selectedOption: string = '';
  @Input() public selectedOptions: ComboboxOption[] = [];
  @Input() public dgatInput: string = 'combobox';
  @Input('id') public hostId: string | number = nextId++;
  @Input() public allowEmpty: boolean = false;

  // Multi select options
  @Input() public isMultiSelect: boolean = false;
  /** If passed, limits the with to 200px (see scss) */
  @Input() public isFixedWidth: boolean = false;
  /** Display the input with default visual styles even after selection */
  @Input() public multiSelectDefaultStyle: boolean = false;
  /** Hides the clear button and makes the apply button full-width */
  @Input() public hideClearButton: boolean = false;
  /** Displays the selected values, rather than the count of selections */
  @Input() public showSelectedOptions: boolean = false;
  /** Allows customization of delimiter when showSelectedOptions is in use */
  @Input() public multiSelectDelimiter: string = ', ';
  @Input() public keepOriginalOrder: boolean = false;

  // Bindings - output
  @Output()
  public selection: EventEmitter<ComboboxOption> =
    new EventEmitter<ComboboxOption>();
  @Output()
  public selections: EventEmitter<ComboboxOption[]> = new EventEmitter<
    ComboboxOption[]
  >();
  @Output() public onBlur = new EventEmitter<any>();

  /** View children as a query list */
  @ViewChildren('listOptions') public listOptions: QueryList<ElementRef>;
  /** View combobox input element ref */
  @ViewChild('inputElement') public inputElement: ElementRef;
  /** View scroll container */
  @ViewChild('scrollContainer') public scrollContainer: ElementRef;
  /** Virtual scroll viewport */
  @ViewChild(CdkVirtualScrollViewport)
  public viewport: CdkVirtualScrollViewport;

  /** Subject when the combobox input model changes */
  public modelChanged = new Subject<string>();
  /** Observable for filtered list of options */
  public filteredOptions$: Observable<ComboboxOption[]> = of(
    [] as ComboboxOption[]
  );
  /** Current selected option index, used to reset selected option after selection */
  public selectedIndex: number = -1;
  /** Focus for the combobox field group */
  public isFieldGroupFocus: boolean = false;
  /** Exact matches toggle for combobox dropdown */
  public isExactMatches: boolean = false;
  /** True when user selects an item in the dropdown */
  public isItemSelected: boolean = false;
  /** The previous search term */
  public prevTerm: string = '';
  /** Displays loadingText until options have been passed in */
  public optionsLoaded: boolean = false;
  /** Current text in the input box */
  public inputText: string = '';
  /** Array of applied selected filtered options */
  public appliedOptions: ComboboxOption[] = [];
  public filteredOptionsLength: number;
  public i18n = this.translate.instant(['Core_LoadingResults']);

  private _isDropdownDisplayed: boolean = false;

  /** Array of pending selected filtered options */
  private pendingOptions: ComboboxOption[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private iconRegistry: DfIconRegistry,
    private renderer: Renderer2,
    private translate: TranslateService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    this.iconRegistry.registerIcons([DfIconChevronDown12]);
    // Debounce model changes for the combobox input
    this.modelChanged
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.isItemSelected = this.selectedOption ? true : false)),
        map((term) =>
          !this.isExactMatches &&
          term.length === 1 &&
          this.prevTerm.length === 0
            ? null
            : this.searchFilteredOptions(term)
        )
      )
      .subscribe();
  }

  /**
   * Determine if multi select has been applied
   */
  public get showMultiSelectStyle(): boolean {
    const value =
      this.isMultiSelect &&
      !this.multiSelectDefaultStyle &&
      !this.isDropdownDisplayed &&
      this.appliedOptions.length > 0;
    return value;
  }

  /**
   * Check to see if the user has any applied or pending options
   */
  public get hasSelections(): boolean {
    return this.appliedOptions.length > 0 || this.pendingOptions.length > 0;
  }

  /**
   * Enable or disable visibility of dropdown list
   */
  public get isDropdownDisplayed() {
    return this._isDropdownDisplayed;
  }
  public set isDropdownDisplayed(value: boolean) {
    if (value !== this._isDropdownDisplayed && value && this.isMultiSelect) {
      this.inputText = '';
      this.reloadFilteredOptions();
    }
    this._isDropdownDisplayed = value;
  }

  public ngAfterViewInit(): void {
    // For a11y: remove the id from the original element, and add it to the button in this component to maintain label to input relationship
    this.renderer.removeAttribute(this.elementRef.nativeElement, 'id');
    this.renderer.setAttribute(
      this.inputElement.nativeElement,
      'id',
      this.hostId as string
    );
  }

  public ngOnChanges(changes: SimpleChanges) {
    // Update filtered options when input options change
    if (changes.options?.currentValue?.length) {
      if (this.isMultiSelect) {
        // Create a deep copy
        this.options = getDeepCopy(this.options);
      }
      // Initialize search with an empty string to display all options
      this.searchFilteredOptions('');

      if (this.isMultiSelect && changes.selectedOptions) {
        this.filteredOptions$.subscribe((options) => {
          this.copyAppliedOptions(options);
          this.updateAppliedOptionsText();
        });
      }
    }

    if (changes.selectedOption) {
      const newOption = changes.selectedOption.currentValue;
      this.isItemSelected = !!newOption;
      if (newOption || this.allowEmpty) {
        this.inputText = newOption;
      }
    }
  }

  public ngOnInit() {
    if (this.selectedOption) {
      this.isItemSelected = true;
      this.inputText = this.selectedOption;
    }
    if (typeof this.labelKey !== 'string' || this.labelKey === undefined) {
      // Throw an error if labelKey isn't provided
      throw new Error('labelKey input binding is not a string or undefined');
    }
  }

  /**
   * Apply the multi-select filtered options
   */
  public applyFilters(): void {
    // Remove the applied options which have been deselected
    this.appliedOptions = this.appliedOptions.filter(
      (value: ComboboxOption) =>
        !this.pendingOptions.some(
          (o) => o[this.labelKey] === value[this.labelKey]
        )
    );
    // Add the new applied options which have been selected
    const addOptions = this.pendingOptions.filter(
      (po) =>
        !this.appliedOptions.some(
          (ao) => ao[this.labelKey] === po[this.labelKey]
        )
    );
    this.copyAppliedOptions(this.appliedOptions.concat(addOptions));
    this.closeDropdown();
  }

  /**
   * Reset all of the filtered options isChecked property value to false
   */
  public clearFilters(): void {
    if (this.isExactMatchesEnabled) {
      this.toggleExactMatches(false);
    }
    this.filteredOptions$.subscribe((options) => {
      options.forEach((o) => (o.isChecked = false));
      this.copyAppliedOptions(options);
      this.closeDropdown(true);
    });
  }

  /**
   * Close the dropdown list
   * @param options Filtered options
   * @param skip Skip the pending options rollback
   */
  public closeDropdown(skip?: boolean): void {
    if (!this.isDropdownDisplayed) {
      return;
    }
    if (!skip) {
      // Rollback the pending options which were NOT applied
      this.pendingOptions.forEach((value) => {
        if (
          this.appliedOptions.findIndex(
            (ao) => ao[this.labelKey] === value[this.labelKey]
          ) === -1
        ) {
          value.isChecked = false;
        }
      });
    }
    this.pendingOptions = [];
    this.selectedOptions = [...this.appliedOptions];
    this.updateAppliedOptionsText();
    this.selections.emit(this.appliedOptions);
    this.toggleDropdown(false);
  }

  public getListboxId(): string {
    return `combobox-listbox-${this.hostId}`;
  }

  /**
   * Handle blur event from combobox input field
   */
  public handleBlur(): void {
    this.toggleDropdown(false);
    if (!this.isItemSelected) {
      this.inputText = '';
      this.selectedIndex = -1;
    } else {
      this.inputText = this.selectedOption;
    }
    this.searchFilteredOptions('');
    this.onBlur.emit();
  }

  /**
   * Navigate through the dropdown list of options
   *
   * @param {KeyboardEvent} event
   */
  public handleKeyup(event: KeyboardEvent): void {
    this.filteredOptions$.subscribe((filteredOptions) => {
      this.filteredOptionsLength = filteredOptions.length;
      this.isDropdownDisplayed = true;

      switch (event.key) {
        case 'Enter':
          this.selectOption(this.selectedIndex);
          break;

        case 'Esc': // IE/Edge specific value
        case 'Escape':
          this.selectedIndex = -1;
          this.setGroupFieldFocus(false);
          break;

        case 'Down': // IE/Edge specific value
        case 'ArrowDown':
          this.isDropdownDisplayed = true;
          this.selectedIndex =
            (this.selectedIndex + 1) % this.filteredOptionsLength;
          this.renderVirtualScroll();
          break;

        case 'Up': // IE/Edge specific value
        case 'ArrowUp':
          this.isDropdownDisplayed = true;
          if (this.selectedIndex <= 0) {
            this.selectedIndex = this.filteredOptionsLength;
          }
          this.selectedIndex =
            (this.selectedIndex - 1) % this.filteredOptionsLength;
          this.renderVirtualScroll();
          break;

        default:
          break;
      }
    });
  }

  /**
   * Navigate through the dropdown list of options
   *
   * @param {KeyboardEvent} event
   */
  public handleMultiSelectKeyup(event: KeyboardEvent): void {
    this.filteredOptions$.subscribe((filteredOptions) => {
      this.isDropdownDisplayed = true;

      switch (event.key) {
        case 'Enter':
          break;

        case 'Esc': // IE/Edge specific value
        case 'Escape':
          this.closeDropdown();
          this.setGroupFieldFocus(false);
          break;

        case 'Down': // IE/Edge specific value
        case 'ArrowDown':
          this.isDropdownDisplayed = true;
          this.renderVirtualScroll();
          break;

        case 'Up': // IE/Edge specific value
        case 'ArrowUp':
          this.isDropdownDisplayed = true;
          this.renderVirtualScroll();
          break;

        default:
          break;
      }
    });
  }

  /**
   * Prevent blur event on parent firing faster than click event
   *
   * @param {Event} event
   */
  public handleMousedown(event: Event): void {
    event.preventDefault();
  }

  /**
   * Checks to see if a group divider is necessary
   * @param option pass in the option from loop on template
   * @param i pass in the index from loop on template
   */
  public isGrouping(option: ComboboxOption, i: number): Observable<boolean> {
    return this.filteredOptions$.pipe(
      switchMap((filtered) => {
        let isGrouping = false;
        if (
          !!option.groupingId &&
          option.groupingId !== filtered[i - 1]?.groupingId
        ) {
          // Add divider above new group
          isGrouping = true;
        } else if (!option.groupingId && !!filtered[i - 1]?.groupingId) {
          // Non-group option following a group also needs a divider
          isGrouping = true;
        }
        return of(isGrouping);
      })
    );
  }

  /**
   * Modifies the filtered dropdown options as per search term
   *
   * @param {KeyboardEvent} event
   */
  public searchFilteredOptions(term: string): void {
    this.prevTerm = term;
    let results: ComboboxOption[] = term
      ? // Filter out options based on search term
        this.options.filter((option) => {
          return this.isExactMatches
            ? option[this.labelKey].toLowerCase() ===
                this.inputText?.toLowerCase()
            : option[this.labelKey]
                .toLowerCase()
                .indexOf(this.inputText?.toLowerCase()) > -1;
        })
      : // Return all options if empty search term
        this.options;

    // Default values for isMultiSelect
    if (this.isMultiSelect) {
      if (results.length > 0) {
        if (!term) {
          // Determine which options are currently applied
          this.selectedOptions?.forEach((value) => {
            const applied = results.find(
              (option) => JSON.stringify(option) === JSON.stringify(value)
            );
            if (applied) applied.isChecked = true;
          });
        }
        results = [...this.sortOptions(results)];
      }
      this.cdr.detectChanges();
    }
    // Return the result set
    this.filteredOptions$ = of(results);
    this.optionsLoaded = true;
    // Trigger change detection
    this.cdr.markForCheck();
  }

  /**
   * Select highlighted option when enter is pressed or any option that is clicked
   *
   * @param {number} index
   */
  public selectOption(index: number): void {
    this.filteredOptions$.subscribe((results) => {
      if (index !== -1) {
        this.selectedOption = results[index][this.labelKey];
        this.inputText = this.selectedOption;
        // Emit selected option to parent
        this.selection.emit(results[index]);
      }

      this.isDropdownDisplayed = false;
      this.selectedIndex = index;
      this.isItemSelected = true;
      this.setGroupFieldFocus(true);
      this.searchFilteredOptions('');
    });
  }

  /**
   * Sets focus on the group field for the combobox
   *
   * @param {boolean} hasFocus
   */
  public setGroupFieldFocus(hasFocus: boolean): void {
    this.isFieldGroupFocus = hasFocus;
    hasFocus
      ? this.inputElement.nativeElement.focus()
      : this.inputElement.nativeElement.blur();
  }

  /**
   * Toggle the dropdown list when input is focused or moves out of focus
   *
   * @param {boolean} isVisible
   */
  public toggleDropdown(isVisible: boolean): void {
    this.isDropdownDisplayed = isVisible;
    // Set focus on input group field
    this.setGroupFieldFocus(isVisible);
    // Select all text in input
    this.selectInputText();

    if (isVisible && this.getOptions) {
      this.options = this.getOptions();
      this.searchFilteredOptions('');
    }

    // Check whether or not to set the virtual scroll container height
    this.filteredOptions$.subscribe(() => {
      this.renderVirtualScroll();
    });
    this.cdr.detectChanges();
  }

  /**
   * Toggle exact matches mode for combobox term searches
   *
   * @param {boolean} isChecked
   */
  public toggleExactMatches(isChecked: boolean): void {
    this.isExactMatches = isChecked;
    this.initSearchFilteredOptions();
    this.cdr.detectChanges();
  }

  /**
   * Toggle a given option's isChecked value (NOTE: Invoked for isMultiSelect only)
   * @param {any} $event
   * @param {Option} option
   * @param {number} i
   */
  public toggleSelection($event: any, option: ComboboxOption, i: number): void {
    option.isChecked = $event.target.checked;
    const index = this.pendingOptions.findIndex((o) => o === option);
    if (index !== -1) {
      this.pendingOptions[index] = option;
    } else {
      this.pendingOptions.push(option);
    }
    this.scrollElementIntoView(i);
  }

  /**
   * For angular to keep track of the long list of results
   *
   * @param {Number} _index
   */
  public trackById = (_index: number, option: ComboboxOption): string => {
    return option[this.trackBy];
  };

  /**
   * Deep copy the current filtered options which are applied
   * @param options
   */
  private copyAppliedOptions(options: ComboboxOption[]): void {
    const list = options.filter((o) => o.isChecked);
    this.appliedOptions = getDeepCopy(list);
  }

  /**
   * Initiates the search for options with current term value
   */
  private initSearchFilteredOptions(): void {
    // Grab current input term value from combobox
    const term = this.inputElement.nativeElement.value;
    // Init search and filter options
    if (term) {
      this.searchFilteredOptions(term);
    }
  }

  /**
   * Reload the filtered options
   */
  private reloadFilteredOptions(): void {
    // Apply the applied options
    if (this.appliedOptions.length > 0) {
      const list = this.options.filter((o) =>
        this.appliedOptions.some((ao) => ao[this.labelKey] === o[this.labelKey])
      );
      list.forEach((o) => (o.isChecked = true));
    }

    // Refresh the filtered options
    this.options = this.sortOptions(this.options);
    this.filteredOptions$ = of(this.options);
  }

  /**
   * Set the virtual scroll container height accordingly
   * @param isVisible
   */
  private renderVirtualScroll(): void {
    setTimeout(() => {
      if (this.scrollContainer) {
        const element = this.scrollContainer.nativeElement;
        // Remove the css class
        const cssClass = 'dropdown-menu--empty';
        if (element.classList.contains(cssClass)) {
          element.classList.remove(cssClass);
        }
        // Calculate the new height of the scroll container
        const offsetHeight =
          element.offsetHeight +
          this.viewport._contentWrapper.nativeElement.offsetHeight +
          // Padding top and bottom from type ahead-list css class (_ui-dropdown-menu.scss)
          6;
        this.renderer.setStyle(element, 'height', `${offsetHeight}px`);
        this.scrollElementIntoView(this.selectedIndex);
      }
    });
  }

  /**
   * Scroll element into view on up and down key events
   *
   */
  private scrollElementIntoView(index: number): void {
    if (index > 0) {
      this.viewport?.scrollToIndex(index - 1, 'smooth');
    }
    this.renderer.setAttribute(
      this.inputElement.nativeElement,
      'aria-activedescendant',
      `listbox-item-${index}`
    );
  }

  /**
   * Selects the current text in the combobox input fieldtabindex="0"
   */
  private selectInputText(): void {
    if (this.isDropdownDisplayed) {
      this.inputElement.nativeElement.select();
    }
  }

  /**
   * Sort options by isChecked and labelKey properties
   * @param options
   */
  private sortOptions(options: ComboboxOption[]): ComboboxOption[] {
    if (options.length === 0) {
      return options;
    }
    let selected = options.filter((o) => o.isChecked);

    let unselected = options.filter((o) => !o.isChecked);
    if (!this.keepOriginalOrder) {
      selected = selected.sort((a, b) => sortBy(a, b, [this.labelKey]));
      unselected = unselected.sort((a, b) => sortBy(a, b, [this.labelKey]));
    }

    options = [...selected.concat(unselected)];
    return options;
  }

  /**
   * Update the input text when the filtered options are applied
   */
  private updateAppliedOptionsText(): void {
    if (this.showSelectedOptions) {
      this.inputText =
        this.appliedOptions.length > 0
          ? this.appliedOptions
              .map((o) => o[this.labelKey])
              .join(this.multiSelectDelimiter)
          : this.placeholder;
    } else {
      this.inputText =
        this.appliedOptions.length > 0
          ? `${this.placeholder} ${this.appliedOptions.length}`
          : '';
    }
  }
}
