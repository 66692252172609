/* eslint-disable @typescript-eslint/no-explicit-any */
import { freeze } from 'immer';
import { cloneDeep } from 'lodash-es';
import { forkJoin, from, Observable, of, pipe } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { AuthUser } from '@app/account/account-api.model';
import { OrgBrandingLayoutService } from '@app/orgs/services';
import { OrgHelpMenuService } from '@app/orgs/services/org-help-menu.service';
import {
  AuthService,
  ContextService,
  LDFlagsService,
  NavigationService,
  TargetsService,
  TrackerService,
  WebEnvironmentService,
} from '@app/shared/services';
import { TeamFlagsService } from '@app/team/services/team-flags.service';

import { LayoutConfiguration } from '@degreed/apollo-angular';
import * as DegreedLayout from './layout-configuration.json';

import {
  addAnalyticTrackers,
  updateAdminNavigation, updateHelpMenu, updatei18n,
  updateLearnerNavigation, updateNavigationBranding,
  updateUrls, updateUser, updateVisibility,
} from './layout-configuration.utils';
import { resolveOrgInfo } from './permission.factory';

/**
 * LayoutConfiguration Service
 * 
 * This Angular version (layout-configuration.service.ts) uses RxJS and streams
 * The React version (layout-configuration.hook.ts) does NOT use RxJS 
 * 
 * Note: The `layout-configuration.utils.ts` is shared between the Angular and React versions
 * 
 */


/**
 * Private internal cache of the layout configuration
 */
let layout$: Observable<LayoutConfiguration>;

/**
 * Return a LayoutConfiguration object based on the user's default organization
 * NOTE: Currently we use compiled configuration files: embedded JSON that is runtime transformed.
 *       !! This configuration should be constructed on the server
 *
 * @returns Observable<LayoutConfiguration>
 */
export const buildLayoutConfiguration_v3 = (
  auth: AuthService,
  translate: TranslateService,
  environment: WebEnvironmentService,
  targets: TargetsService,
  navigation: NavigationService,
  featureFlags: LDFlagsService,
  context: ContextService,
  teamFlags: TeamFlagsService,
  helpMenu: OrgHelpMenuService,
  trackerService: TrackerService,
  brandingService: OrgBrandingLayoutService,
): Observable<LayoutConfiguration> => {
  // Customize the configuration JSON based on the user's organization, i18n and other information
  const buildLayout = pipe(

    switchMap((user: AuthUser | undefined) => {
      // Gather async data before customizing layout
      const organizationId = user?.defaultOrgId;
      const supportInfo$ = helpMenu.getSupportInfo(organizationId);
      const learnInSSOUrl$ = navigation.getLearnInSSOUrl(organizationId);
      const featuredPlanId$ = targets.getBrowseTarget(organizationId).pipe(map((target) => target.targetId));
      const branding$ = from(brandingService.loadBranding());

      return forkJoin([ of(user), supportInfo$, featuredPlanId$, learnInSSOUrl$, branding$ ]);
    }),

    map(([ user, supportInfo, featuredPlanId, learnInSSOUrl, branding ]): LayoutConfiguration => {

      const analyticsUrl = '/analytics?tag=1';
      const [,, orgInfo] = resolveOrgInfo(user);

      let configuration = cloneDeep({...DegreedLayout}) as LayoutConfiguration;

      // Update i18n asap to get any placeholder variables used in translations updated 
      // in the JSON before any subsequent utils functions are called
      
      configuration = updateVisibility(configuration) as LayoutConfiguration;
    
      configuration = updatei18n(configuration, translate);
      configuration = updateUser(configuration, user, environment);
      configuration = updateUrls( configuration, user, featuredPlanId, learnInSSOUrl, analyticsUrl );

      configuration = updateNavigationBranding(configuration, branding);
      configuration = updateAdminNavigation(configuration, { authUser: user, orgInfo, featureFlags });
      configuration = updateLearnerNavigation( configuration, user, featureFlags, teamFlags, auth, context );

      configuration = updateHelpMenu(configuration, orgInfo.organizationId, supportInfo);
      configuration = addAnalyticTrackers(configuration, trackerService);

      return freeze(configuration);
    })
  );

  layout$ ||= auth.authUser$.pipe(buildLayout);

  return layout$;
};
