import { Inject, Injectable } from '@angular/core';
import { RecommendationsModalService } from '@app/recommendations/services/recommendations-modal.service';
import {
  SearchOrigin,
  SearchSubmitMethod,
  SearchTrackerService,
} from '@app/search/services/search-tracker.service';
import { Tag as PascalCaseTag } from '@app/shared/ajs/pascal-cased-types.model';
import { DgError } from '@app/shared/models/dg-error';
import { RecommendActions } from '@app/shared/models/recommendations.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { AuthService } from '@app/shared/services/auth.service';
import { ModalService } from '@app/shared/services/modal.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { SearchUrlService } from '@app/shared/services/search-url.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { camelCaseKeys } from '@app/shared/utils/property';
import { WindowToken } from '@app/shared/window.token';
import { ReplaceFocusInterestModal } from '@app/tags/components/replace-focus-interest-modal/replace-focus-interest-modal.component';
import { EvaluationService } from '@app/tags/services/evaluation.service';
import {
  TagRatingSkillEngagementType,
  TagRatingTrackerService,
  TagRatingUpdatedActions,
} from '@app/tags/services/tag-rating-tracker.service';
import { TagsApi } from '@app/tags/tag-api.model';
import {
  InternalTagRatingTypes,
  RatingLevelNames,
  TagRatingResponse,
} from '@app/tags/tags';
import {
  TargetSuggestionType,
  TitleSuggestion,
} from '@app/target/target-api.model';
import { UserInterest, UserProfileSummary } from '@app/user/user-api.model';
import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  of,
  Subject,
  Subscription,
  throwError,
  combineLatest,
  BehaviorSubject,
} from 'rxjs';
import {
  catchError,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { TagRatingTypePipe } from '../pipes/tag-rating-type.pipe';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { catchAndSurfaceError } from '@app/shared/utils';
import { OrgRatingScaleLevel } from '@app/account/account-api.model';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { Skill } from '@app/opportunities/opportunities-api.model';
import { SimilarSkillsResponse } from '@app/onboarding/onboarding-api.model';
import { Proficiency } from '@app/skills-platform-lxp-data-access/skill-proficiency/skill-proficiency.model';

@Injectable({
  providedIn: 'root',
})
export class TagsService extends ApiServiceBase {
  public tagActions = {
    degreedCertified: 'Credential',
    managerReview: 'Manager',
    skillReview: 'Evaluation',
    selfRating: 'Self',
    follow: 'Follow',
  };
  private i18n: { [key: string]: string } = {};

  private _userTagsModified = new Subject<any>();

  /** Subscribe to be notified of updates to a users tags (skills) */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly userTagsModified = this._userTagsModified.asObservable();

  // to reset the suggested role tags cache
  private refresh$ = new BehaviorSubject<void>(null);

  /**
   * Observable of the suggested tags based on the user's role
   * Cached using shareReplay(1) to avoid multiple API calls
   */
  private suggestedTagsBasedOnUserRole$: Observable<TagsApi.Tag[]> =
    this.refresh$.pipe(
      switchMap(() => this.SkillsSuggestionsBasedOnUserRole()),
      shareReplay(1)
    );

  constructor(
    http: NgxHttpClient,
    private translateService: TranslateService,
    private trackerService: TrackerService,
    private notifierService: NotifierService,
    private evaluationService: EvaluationService,
    private authService: AuthService,
    private tagRatingTrackerService: TagRatingTrackerService,
    private searchTrackerService: SearchTrackerService,
    private searchUrlService: SearchUrlService,
    private modalService: ModalService,
    private webEnvironmentService: WebEnvironmentService,
    private tagRatingType: TagRatingTypePipe,
    private ldFlagsService: LDFlagsService,
    @Inject(WindowToken) private windowRef: Window,
    private recommendationsModalService: RecommendationsModalService
  ) {
    super(http, translateService.instant('InputsSvc_GeneralError'));
    this.i18n = this.translateService.instant([
      'Core_Continue',
      'dgTagsList_RemoveWorkdaySkillTitle',
      'dgTagsList_VisitWorkday',
      'dgTagRating_SelfAssessment',
      'dgTagRating_Credential',
      'dgTagRating_Evaluation',
      'dgTagRating_RatingType',
      'dgTagRating_SelectRating',
      'TagsSvc_GetTagRatingError',
      'TagsSvc_CategoryError',
      'UserProfileSvc_ProblemUpdatingSkillCheckup',
    ]);
  }

  /**
   * Get observable of the cached rating level names
   */
  private get levelNames(): RatingLevelNames {
    if (this.authService.authUser?.orgRatingLabels) {
      return this.transformRatingLevelNames(
        this.authService.authUser.orgRatingLabels
      );
    }
    return {};
  }

  public get orgLevels(): OrgRatingScaleLevel[] {
    const levels = this.authService.authUser?.orgRatingScale?.levels;
    if (levels) {
      return levels.sort((a, b) => a.value - b.value);
    }
    return [];
  }

  /** Notify `userTagsModified` listeners that a users tags have been udpated */
  public notifyUserTagsModified(payload?: any): void {
    return this._userTagsModified.next(payload);
  }

  public getNameForRatingLevel(level: number): string {
    return this.levelNames[level];
  }

  /**
   * Search for content matching the given tag
   * @param item Tag
   */
  public findContentForTag(tag: TagsApi.Tag | PascalCaseTag): void {
    const _tag: TagsApi.Tag = camelCaseKeys(tag);
    this.searchTrackerService.setSearchData({
      submitMethod: SearchSubmitMethod.findContentClicked,
    });
    this.searchTrackerService.searchInitiated({
      searchTerm: _tag.name,
      origin: SearchOrigin.actionOptionMenu,
    });

    const url = this.searchUrlService.getGlobalSearchURL(
      _tag.title || _tag.name
    );
    this.windowRef.open(url, '_self');
  }

  public createTags(tags: string[]): Observable<TagsApi.Tag[]> {
    return this.http
      .post<TagsApi.Tag[]>('/tag/create', { TagNames: tags })
      .pipe(
        catchError((error) =>
          throwError(
            this.createDgError({
              error,
              message: this.translateService.instant('TagsSvc_CreateError'),
            })
          )
        )
      );
  }

  public recommendTag(tag: PascalCaseTag | TagsApi.Tag): Observable<any> {
    const _tag: TagsApi.Tag = camelCaseKeys(tag);
    const authUser = this.authService.authUser;

    let tagRatingTypes: RecommendActions[] = [
      {
        name: this.i18n.dgTagRating_SelfAssessment,
        targetType: 'Self',
      },
    ];
    const isEvaluable$ = this.evaluationService.getIsEvaluableByTagId(
      _tag.tagId
    );

    return isEvaluable$.pipe(
      take(1),
      switchMap((isEvaluable) => {
        if (authUser.canEvaluateSkills && isEvaluable) {
          tagRatingTypes = [
            ...tagRatingTypes,
            {
              name: this.i18n.dgTagRating_Evaluation,
              targetType: 'Evaluation',
            },
          ];
        }

        const enhancedSkill = {
          ..._tag,
          resourceType: 'Tag',
          actions: tagRatingTypes,
          actionsLabel: {
            label: this.i18n.dgTagRating_RatingType,
            placeholder: this.i18n.dgTagRating_SelectRating,
          },
        };

        return this.recommendationsModalService.showShareModal(
          camelCaseKeys(enhancedSkill)
        );
      })
    );
  }

  public recommendTags(tagShare: {
    recipientProfileKey: number;
    resourceIds: number[];
    orgId?: number;
  }): Observable<void> {
    const orgId = tagShare.orgId ?? this.authService.authUser.defaultOrgId;
    return this.http
      .post<void>('/managers/recommendresources', {
        RecommendationType: 'Recommendation',
        ResourceType: 'Tag',
        ResourceIds: tagShare.resourceIds,
        Action: null,
        OrgId: orgId,
        DateDue: null,
        UserProfileKeys: [tagShare.recipientProfileKey],
      })
      .pipe(
        catchError((error) =>
          throwError(
            this.createDgError({
              error,
              message: this.translateService.instant(
                'TagsSvc_RecommendSkillError'
              ),
            })
          )
        )
      );
  }

  public getTag(id: number): Observable<TagsApi.Tag> {
    return this.http.get<TagsApi.Tag>('/tag/getTag', { params: { id } }).pipe(
      catchError((error) =>
        throwError(
          this.createDgError({
            error,
            message: this.translateService.instant('TagsSvc_GetTagError'),
          })
        )
      )
    );
  }

  public getTagMetadata(
    tagId: number,
    tagType: string = 'Skill'
  ): Observable<TagsApi.TagMetadata> {
    return this.http
      .get<TagsApi.TagMetadata>('/tag/GetTagMetadata', {
        params: { tagId, tagType },
      })
      .pipe(
        catchError((error) =>
          throwError(
            this.createDgError({
              error,
              message: this.translateService.instant(
                'TagsSvc_GetTagRatingError'
              ),
            })
          )
        )
      );
  }

  public getLinkedTags(tagId: number): Observable<TagsApi.Tag> {
    return this.http
      .get<TagsApi.Tag>('/tag/GetOntologyLinkedSkills', {
        params: { tagId },
      })
      .pipe(
        catchError((error) =>
          throwError(
            this.createDgError({
              error,
              message: this.translateService.instant('TagsSvc_GetTagError'),
            })
          )
        )
      );
  }

  /**
   * Get the latest ratings for each rating type (credential/evaluation/manager/self/external)
   * including related meta data for raters/endorsers etc
   * @param userKey
   * @param tagId
   * @returns Observable<UserTagRatingDetails[]>
   */
  public getTagRatingDetails(
    userKey: number,
    tagId: number,
    isManager?: boolean
  ): Observable<TagsApi.UserTagRatingDetails[]> {
    const endpoint = isManager
      ? '/managers/GetUserTagRatingDetails'
      : '/tag/GetTagRatingDetails';

    // handle difference in param naming
    const params = isManager
      ? {
          orgId: this.authService.authUser.defaultOrgId,
          userProfileKey: userKey,
          tagId,
        }
      : {
          userKey,
          tagId,
        };
    return this.http
      .get<TagsApi.UserTagRatingDetails[]>(endpoint, {
        params,
      })
      .pipe(
        catchError((error) =>
          throwError(
            this.createDgError({
              error,
              message: this.translateService.instant(
                'TagsSvc_GetTagRatingDataError'
              ),
            })
          )
        )
      );
  }

  /**
   * Get the user who completed a manager/peer rating
   * @param userKey
   * @param tagId
   * @param raterKey
   */
  public getTagRatingRater(
    userKey: number,
    tagId: number,
    raterKey: number
  ): Observable<UserProfileSummary> {
    return this.http
      .get<UserProfileSummary>('/tag/GetTagRatingRater', {
        params: { userKey, tagId, raterKey },
      })
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(
              this.translateService.instant('TagsSvc_GetTagRatingError'),
              error
            )
          )
        )
      );
  }

  /**
   * Get the user who requested a rating
   *
   * @param userKey
   * @param tagId
   * @param raterKey
   */
  public getTagRatingRequester(
    userKey: number,
    tagId: number,
    raterKey: number
  ): Observable<UserProfileSummary> {
    return this.http
      .get<UserProfileSummary>('/tag/GetTagRatingRequester', {
        params: {
          userKey,
          tagId,
          raterKey,
        },
      })
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(
              this.translateService.instant('TagsSvc_GetTagRatingError'),
              error
            )
          )
        )
      );
  }

  /**
   * When following a user skill tag update the user interest and broadcast that the user tags have been modified
   *
   * @param tag current user's tag
   * @param location for tracking events
   */
  public addUserTag(
    tag: TagsApi.Tag,
    location?: string
  ): Observable<TagsApi.TagDetails> {
    return this.http.post('/user/adduserinterest', tag).pipe(
      tap((response: TagsApi.TagDetails) => {
        this.notifyUserTagsModified(tag);
        // Adding in location from webEnvironmentService as there seems to be an issue with the tracker
        // service adding in a location.
        location = location
          ? location
          : this.webEnvironmentService.analyticsAppLocation;
        this.tagRatingTrackerService.trackSkillEngagementUpdated(
          TagRatingSkillEngagementType.follow,
          true,
          response,
          location
        );
      }),
      catchError((error) => {
        const message = this.translateService.instant('TagsSvc_AddSkillError');
        this.notifierService.showError(message);
        return throwError(this.createDgError({ error, message }));
      })
    );
  }

  /**
   * Add multiple user tags and broadcast that the user tags have been modified
   * @param tags user's tags to add
   * @param location for tracking events
   */
  public addUserTags(
    tags: Partial<TagsApi.Tag[]>,
    location?: string,
    doNotify: boolean = true
  ) {
    return this.http.post('/user/AddUserInterests', { tags }).pipe(
      tap(() => {
        if (doNotify) {
          this.notifyUserTagsModified();
        }
        const tagEvents = [];
        // Adding in location from webEnvironmentService as there seems to be an issue with the tracker
        // service adding in a location.
        location = location
          ? location
          : this.webEnvironmentService.analyticsAppLocation;
        tags.map((tag) => {
          tagEvents.push({
            eventName: 'Skill Engagement Updated',
            properties: {
              EngagementType: TagRatingSkillEngagementType.follow,
              ChangedTo: true,
              SkillId: tag.tagId,
              SkillName: tag.name,
              IsFocusSkill: false,
              IsOnProfile: true,
              Location: location,
            },
          });
        });
        this.trackerService.trackBatchEvents(tagEvents);
      }),
      catchError((error) =>
        throwError(
          this.createDgError({
            error,
            message: this.translateService.instant('TagsSvc_CategorySaves'),
          })
        )
      )
    );
  }

  public getRequestedTagRating(
    userKey: number,
    tagId: number,
    type:
      | InternalTagRatingTypes.peer
      | InternalTagRatingTypes.manager
      | InternalTagRatingTypes.self
  ): Observable<TagsApi.UserTagRating> {
    return this.get<TagsApi.UserTagRating>(
      '/tag/GetRequestedTagRating',
      { userKey, tagId, type },
      this.i18n.TagsSvc_GetTagRatingError
    );
  }

  public rateTag(
    level: number,
    type: InternalTagRatingTypes,
    tag: TagsApi.Tag,
    location?: string,
    element?: HTMLElement
  ): Observable<TagRatingResponse> {
    return this.http
      .post<TagRatingResponse>('/tag/ratetag', {
        tagId: tag.tagId,
        type,
        level,
      })
      .pipe(
        tap(() => {
          // Add the tag with the updated level as update payload
          this.notifyUserTagsModified(tag);
          this.tagRatingTrackerService.trackSkillRatingUpdated(
            type,
            level
              ? TagRatingUpdatedActions.added
              : TagRatingUpdatedActions.removed,
            Number(tag.ratings?.find((rating) => rating.type === type)?.level),
            level,
            tag,
            null,
            location,
            element as HTMLElement
          );
        }),
        catchError((error) =>
          throwError(
            this.createDgError({
              error,
              message: this.translateService.instant('TagsSvc_RateTagError'),
            })
          )
        )
      );
  }

  public completeSkillsCheckup(notify: boolean = true): Observable<null> {
    return this.post<null>(
      '/userprofile/completeusertagratingscheckup',
      null,
      this.translateService.instant(
        this.i18n.UserProfileSvc_ProblemUpdatingSkillCheckup
      )
    ).pipe(
      tap(
        () =>
          notify && this.notifyUserTagsModified({ setHasSkillsCheckup: true })
      )
    );
  }

  public deleteTagRating(userTagRatingId: number, orgId?): Observable<void> {
    let endpoint, params;
    if (orgId) {
      endpoint = '/managers/DeleteTagRating';
      params = { orgId, userTagRatingId };
    } else {
      endpoint = '/tag/DeleteTagRating';
      params = { userTagRatingId };
    }

    return this.http
      .delete(endpoint, {
        params,
      })
      .pipe(
        catchError((error) =>
          throwError(
            this.createDgError({
              error,
              message: this.translateService.instant(
                'TagsSvc_DeleteTagRatingError'
              ),
            })
          )
        )
      );
  }

  public updateTagRatingPrivacy(
    userTagRatingId: number,
    privacyId: number
  ): Observable<TagsApi.UserTagRating> {
    return this.post<TagsApi.UserTagRating>(
      '/tag/UpdateTagRatingPrivacy',
      {
        userTagRatingId,
        privacyId,
      },
      this.translateService.instant('TagsSvc_UpdatePrivacyError')
    ).pipe(tap(() => this.notifyUserTagsModified()));
  }

  /**
   * When un-following a user skill tag update the user interest and broadcast that the user tags have been modified
   *
   * @param tag current user's tag
   * @param location for tracking events
   */
  public removeUserTag(
    tag: TagsApi.TagDetails | TagsApi.Tag,
    location?: string
  ): Observable<undefined> {
    const inputs = {
      canCancel: true,
      closeOnSubmit: false,
      headerText: this.translateService.instant('Core_RemoveSkill'),
      bodyText: this.translateService.instant(
        tag?.requiredBySkillStandard
          ? 'dgTagRating_Critical_RemoveFromProfileConfirmation'
          : 'dgTagRating_RemoveFromProfileConfirmation',
        { skill: tag.title }
      ),
      submitButtonText: this.translateService.instant('Core_Remove'),
    };
    return this.modalService
      .show<void>(SimpleModalComponent, {
        inputs,
        errorOnDismiss: true,
      })
      .pipe(
        switchMap(() => {
          return this.http
            .post<undefined>('/user/removeuserinterest', tag)
            .pipe(
              tap(() => {
                this.notifyUserTagsModified(tag);
                tag.isFollowing = false;
                this.tagRatingTrackerService.trackSkillEngagementUpdated(
                  TagRatingSkillEngagementType.removed,
                  false,
                  { ...tag, isFollowing: false, isFocused: false },
                  location
                );
                this.notifierService.showSuccess(
                  this.translateService.instant(
                    'dgProfileOverview_RemoveSkill',
                    {
                      skillName: tag.title,
                    }
                  )
                );
              }),
              catchError((error) => {
                const message = this.translateService.instant(
                  'TagsSvc_RemoveSkillError'
                );
                this.notifierService.showError(message);
                return throwError(this.createDgError({ error, message }));
              })
            );
        })
      );
  }

  /**
   * When un-following a user Workday skill tag update notify the user that they should also update in their Workday profile
   *
   * @param tag current user's tag
   */
  public removeWorkdayUserTagModal(tag): Observable<boolean> {
    const inputs = {
      canCancel: true,
      closeOnSubmit: false,
      headerText: this.i18n.dgTagsList_RemoveWorkdaySkillTitle,
      bodyText: `${this.translateService.instant(
        'dgTagsList_RemoveWorkdaySkillInfo',
        {
          skillName: tag.title,
        }
      )} <a class="link ib color-blue par--small font-medium" target="_blank" href="https://www.workday.com/"> ${
        this.i18n.dgTagsList_VisitWorkday
      } ></a>`,
      submitButtonText: this.i18n.Core_Continue,
    };
    return this.modalService.show<boolean>(SimpleModalComponent, {
      inputs,
      errorOnDismiss: true,
    });
  }

  public toggleUserInterestFocus(
    tag: TagsApi.TagDetails
  ): Observable<void | Subscription> {
    if (tag.requestingUserIsFocused) {
      return this.http.post<void>('/user/RemoveUserInterestFocus', tag).pipe(
        tap(() => {
          this.notifierService.showSuccess(
            this.translateService.instant('TagsSvc_RemoveFocusSkillSuccess')
          );
          this.updateUserTagsModified(tag);
        }),
        catchError((error) => {
          // The user might click 'Cancel' in the replace focus skill modal and
          // the modal promise will reject with 'undefined'. We don't
          // want to show this error message in that case.
          if (error !== undefined) {
            return throwError(
              this.createDgError({
                error,
                message: this.translateService.instant(
                  'TagsSvc_UpdateFocusSkillsError'
                ),
              })
            );
          }
        })
      );
    } else {
      return this.http.post<void>('/user/AddUserInterestFocus', tag).pipe(
        tap(() => {
          this.notifierService.showSuccess(
            this.translateService.instant('TagsSvc_AddFocusSkillSuccess')
          );
          this.updateUserTagsModified(tag);
        }),
        catchError((error) => {
          // Handle the focus skills already at the limit error by showing
          // the replace skill modal to select a skill to replace, then do that.
          if (error.error === 'TooManyFocusSkills') {
            return of(
              this.modalService
                .show(ReplaceFocusInterestModal, {
                  inputs: { tagsService: this },
                })
                .subscribe((oldTag: TagsApi.TagDetails) => {
                  this.replaceUserInterestFocus(oldTag, tag).subscribe();
                })
            );
          } else {
            // Just re-throw any other error
            throw error;
          }
        })
      );
    }
  }

  /**
   * The returned tags are ordered by when a user added them to their profile
   *
   * @param ownerUserProfileKey
   */
  public getUserProfileTags(ownerUserProfileKey?: number): Observable<{
    focus: TagsApi.Tag[];
    required: TagsApi.Tag[];
    other: TagsApi.Tag[];
  }> {
    return this.http
      .get<{
        focus: TagsApi.Tag[];
        required: TagsApi.Tag[];
        other: TagsApi.Tag[];
      }>('/user/getuserprofiletags', {
        params: {
          ownerUserProfileKey,
        },
      })
      .pipe(
        catchError((error) =>
          throwError(
            this.createDgError({
              error,
              message: this.translateService.instant(`TagsSvc_CategoryError`),
            })
          )
        )
      );
  }

  /**
   * The returned tags are ordered by when a user added them to their profile
   *
   * @param includeRatings
   * @param doCache
   * @param focusedOnly
   */
  public getUserTopTags(
    includeRatings: boolean,
    cache: boolean,
    focusedOnly = false
  ): Observable<UserInterest[]> {
    return this.http
      .get<UserInterest[]>('/user/getuserinterests', {
        params: { includeRatings, focusedOnly },
        cache,
      })
      .pipe(
        map((tags) =>
          tags.map((tag: UserInterest) => ({
            ...tag,
            isFollowing: true,
          }))
        ),
        catchError((error) =>
          throwError(
            this.createDgError({
              error,
              message: this.translateService.instant(`TagsSvc_SkillTagsError`),
            })
          )
        )
      );
  }

  public getPopularTags(): Observable<TagsApi.Tag[]> {
    return this.http.get<TagsApi.Tag[]>('/learning/getpopulartags').pipe(
      map((tags) =>
        tags.map((tag: TagsApi.Tag) => ({
          ...tag,
          isFollowing: true,
        }))
      ),
      catchError((error) =>
        throwError(
          this.createDgError({
            error,
            message: this.translateService.instant(`TagsSvc_CategoryError`),
          })
        )
      )
    );
  }

  /**
   * @param tagId
   */
  public getAvailableRatingTypes(
    tagId: number
  ): Observable<TagsApi.RatingType[]> {
    return this.http
      .get<TagsApi.RatingType[]>('/tag/GetAvailableRatingTypes', {
        params: { tagId },
      })
      .pipe(
        catchError((error) =>
          throwError(
            this.createDgError({
              error,
              message: this.translateService.instant(
                `TagsSvc_GetTagRatingError`
              ),
            })
          )
        )
      );
  }

  /**
   * This function will call api/suggest which will go to the 'internet' to get tag suggestions.
   * - Google *default, if no provider is set
   * - dbpedia
   */
  public suggestTag(
    term: string,
    count = 20,
    returnLevels = false
  ): Observable<TitleSuggestion[]> {
    // This is a similar implementation as the extension's tagsSvc.ts
    // Please consider both when making changes
    // We are no longer going to be using Google API for 'topics'. We will now use the skills taxonomy for all skill searches.

    const disableLearnerSkillsRegistry: boolean =
      this.authService.authUser?.disableLearnerSkillsRegistry !== undefined
        ? this.authService.authUser.disableLearnerSkillsRegistry
        : true;
    let endPoint = '/targets/getsuggestedtitles';
    let params: {
      params: {
        targetType?: TargetSuggestionType;
        term: string;
        count: number;
        returnLevels?: boolean;
      };
    } = {
      params: {
        targetType: 'Skill',
        term,
        count,
        returnLevels,
      },
    };

    if (!disableLearnerSkillsRegistry) {
      endPoint = '/skills';
      params = {
        params: {
          term,
          count,
        },
      };
    }

    return this.http.get<TitleSuggestion[]>(endPoint, params).pipe(
      tap(() => {
        this.trackerService.trackEventData({
          action: 'Skill Catalog Searched',
          properties: {
            Keywords: term,
          },
        });
      }),
      catchError((error) =>
        throwError(
          this.createDgError({
            error,
            message: this.translateService.instant('TagsSvc_CategoryError'),
          })
        )
      )
    );
  }

  /**
   * Get all available skill levels and descriptions
   * @returns Observable<Proficiency>
   */
  public getOrgProficiencyLevels(): Observable<Proficiency> {
    return this.http.get(`/skills/skillproficiency/defaults`);
  }

  /**
   * TODO: refactor to use requestTagRating
   * For a manager to request that a subordinate completes a self rating
   *
   * @param tagId
   * @param raterProfileKey
   * @param rateeProfileKey
   * @param requesterProfileKey
   * @param comment
   */
  public requestSelfTagRating(
    tagId: number,
    raterProfileKey: number,
    rateeProfileKey?: number,
    requesterProfileKey?: number,
    comment?: string
  ): Observable<any> {
    return this.http
      .post<TagRatingResponse>('/managers/requesttagrating', {
        tagId,
        type: InternalTagRatingTypes.self,
        raterProfileKey,
        rateeProfileKey,
        requesterProfileKey,
        comment,
      })
      .pipe(
        tap(() => {
          this.notifierService.showSuccess(
            this.translateService.instant('RequestRating_SuccessMessage')
          );
        }),
        catchError((error) =>
          throwError(
            this.createDgError({
              error,
              message: this.translateService.instant(
                'TagsSvc_RequestTagRatingError'
              ),
            })
          )
        )
      );
  }

  /**
   * For a user to request a single rating (manager/peer) from another user
   *
   * @param tagId
   * @param type
   * @param raterProfileKey
   * @param comment
   */
  public requestTagRating(
    tagId: number,
    type: InternalTagRatingTypes.manager | InternalTagRatingTypes.peer,
    raterProfileKey: number,
    comment?: string
  ): Observable<TagRatingResponse> {
    return this.http
      .post<TagRatingResponse>('/tag/requesttagrating', {
        tagId,
        type,
        raterProfileKey,
        rateeProfileKey: undefined,
        requesterProfileKey: undefined,
        comment,
      })
      .pipe(
        tap(() => {
          this.notifierService.showSuccess(
            this.translateService.instant('RequestRating_SuccessMessage')
          );
        }),
        catchError((error) =>
          throwError(
            this.createDgError({
              error,
              message: this.translateService.instant(
                'TagsSvc_RequestTagRatingError'
              ),
            })
          )
        )
      );
  }

  /**
   * For a user to request multiple ratings (manager/peer) from groups or users
   *
   * @param tagId
   * @param type
   * @param raterProfileKeys
   * @param raterGroupIds
   * @param comment
   */
  public requestTagRatings(
    event: Event,
    tag: TagsApi.TagDetails,
    type: InternalTagRatingTypes.manager | InternalTagRatingTypes.peer,
    raterProfileKeys?: number[],
    raterGroupIds?: number[],
    comment?: string,
    cancelPendingRequest?: boolean
  ): Observable<{ userTagRatingIds: number[] }> {
    return this.http
      .post<{ userTagRatingIds: number[] }>('/tag/requesttagratings', {
        tagId: tag.tagId,
        type,
        raterProfileKeys,
        raterGroupIds,
        comment,
        cancelPendingRequest,
      })
      .pipe(
        tap(() => {
          this.tagRatingTrackerService.trackRatingRequested(
            event?.target as HTMLElement,
            tag,
            type,
            'requested',
            undefined,
            this.authService.authUser?.viewerProfile.userProfileKey, // logged in user is the owner here
            undefined,
            (raterProfileKeys || []).length,
            (raterGroupIds || []).length
          );
          this.notifierService.showSuccess(
            this.translateService.instant('RequestRating_SuccessMessage')
          );
        }),
        catchError((error) =>
          throwError(
            this.createDgError({
              error,
              message: this.translateService.instant(
                'TagsSvc_RequestTagRatingError'
              ),
            })
          )
        )
      );
  }

  public rateTagForUser(
    userKey: number,
    level: number,
    comment: string,
    tag: TagsApi.TagDetails,
    ratingType: InternalTagRatingTypes,
    requester: Partial<UserProfileSummary>
  ): Observable<TagRatingResponse> {
    return this.http
      .post<TagRatingResponse>('/tag/RateTagForUser', {
        userKey,
        tagId: tag.tagId,
        type: ratingType,
        level,
        comment,
      })
      .pipe(
        tap((response) => {
          const authUser = this.authService.authUser;
          const successMessage: string = this.translateService.instant(
            ratingType === InternalTagRatingTypes.target
              ? 'Team_UpdateSkillTargetSuccessMessage'
              : 'TagsSvc_UpdateAssociateRatingSuccess',
            {
              ratingType:
                ratingType !== InternalTagRatingTypes.peer
                  ? this.tagRatingType.transform({
                      type: ratingType,
                    } as TagsApi.UserTagRating)
                  : this.translateService.instant('dgTagRating_PeerRating'),
              requester: requester?.name,
            }
          );
          this.notifierService.showSuccess(successMessage);

          this.tagRatingTrackerService.trackSkillRatingUpdated(
            ratingType,
            level
              ? TagRatingUpdatedActions.added
              : TagRatingUpdatedActions.removed,
            Number(
              tag.ratings?.find(
                (rating) =>
                  rating.type === ratingType &&
                  rating.raterProfileKey ===
                    authUser.viewerProfile.userProfileKey
              )?.level
            ),
            level,
            tag,
            userKey
          );
        }),
        catchError((error) =>
          throwError(
            this.createDgError({
              error,
              message: this.translateService.instant('TagsSvc_RateTagError'),
            })
          )
        )
      );
  }

  public getTagSuggestions(
    topUserTagNames: string, // comma separated list of tag names
    count: number,
    jobRole?: string
  ): Observable<{ tags: TagsApi.Tag[]; isFromDs3: boolean }> {
    let url = this.ldFlagsService.useV6SkillsAPI
      ? '/taxonomy/SimilarSkillsSuggestionsV6'
      : '/taxonomy/SimilarSkillsSuggestions';
    return this.http
      .get(url, {
        params: { skills: topUserTagNames, count, role: jobRole },
      })
      .pipe(
        catchAndSurfaceError(
          this.translateService.instant('TagsCtrl_SuggestedSkillsError')
        )
      );
  }

  public SkillsSuggestionsBasedOnUserRoleV2(
    jobRole?: string
  ): Observable<TagsApi.Tag[]> {
    return this.http
      .get<TagsApi.Tag[]>('/taxonomy/GetSimilarRoleSkills', {
        params: { jobRole },
      })
      .pipe(
        catchAndSurfaceError(
          this.translateService.instant('TagsCtrl_SuggestedSkillsError')
        )
      );
  }

  private SkillsSuggestionsBasedOnUserRole(): Observable<TagsApi.Tag[]> {
    let url = '/taxonomy/SkillsSuggestionsBasedOnUserRole';
    return this.http
      .get<TagsApi.Tag[]>(url, { params: { count: 25 } })
      .pipe(
        catchAndSurfaceError(
          this.translateService.instant('TagsCtrl_SuggestedSkillsError')
        )
      );
  }

  public getSkillsSuggestionsByOrg(
    userKey: number,
    orgId: number
  ): Observable<TagsApi.Tag[]> {
    let url = '/SkillStandards/GetUserSkillTargetsSkillsNotOnProfile';
    return this.http
      .get<TagsApi.Tag[]>(url, { params: { userKey, organizationId: orgId } })
      .pipe(
        catchAndSurfaceError(
          this.translateService.instant('TagsCtrl_SuggestedSkillsError')
        )
      );
  }

  public refreshSuggestions(): void {
    this.refresh$.next();
  }

  public skillsSuggestionsBasedOnUserRole(): Observable<TagsApi.Tag[]> {
    return this.suggestedTagsBasedOnUserRole$;
  }

  /**
   * Find the inferred skills for a job role
   * @param jobrole job role to find inferred skills for
   */

  public getJobRoleAIInferredSkills(
    params
  ): Observable<TagsApi.Tag[] | Skill[]> {
    return this.http
      .get<SimilarSkillsResponse>('/taxonomy/getroletoskills', {
        params,
      })
      .pipe(
        map((resp) => resp?.tags),
        map((tags) =>
          tags.map((tag) => ({
            ...tag,
            isAIInferredSkill: true,
          }))
        ),
        catchError((error) => throwError(error))
      );
  }

  public getCanonicalTagsSelectById(
    tagIds: number[]
  ): Observable<TagsApi.CanonicalTag> {
    return this.http
      .post<TagsApi.CanonicalTag>('/tag/getCanonicalTagsSelectById', tagIds)
      .pipe(
        catchError((error) =>
          throwError(
            this.createDgError({
              error,
              message: this.translateService.instant('TagsSvc_GetTagError'),
            })
          )
        )
      );
  }

  public getCanonicalTagsSelectByTag(
    tagNames: string[]
  ): Observable<TagsApi.CanonicalTag> {
    return this.http
      .post<TagsApi.CanonicalTag>('/tag/getCanonicalTagsSelectByTag', tagNames)
      .pipe(
        catchError((error) =>
          throwError(
            this.createDgError({
              error,
              message: this.translateService.instant('TagsSvc_GetTagError'),
            })
          )
        )
      );
  }

  public getAllCanonicalTags(tags: TagsApi.Tag[]) {
    const arr: Observable<TagsApi.CanonicalTag>[] = [];

    const tagIds = tags.filter((t) => t.tagId).map((t) => t.tagId);
    if (tagIds.length) {
      arr.push(this.getCanonicalTagsSelectById(tagIds));
    }

    const tagNames = tags.filter((t) => !t.tagId).map((t) => t.name);
    if (tagNames.length) {
      arr.push(this.getCanonicalTagsSelectByTag(tagNames));
    }

    return combineLatest(arr).pipe(
      map((results: any) => {
        return results.flat();
      })
    );
  }

  public getSynonymsTagsSelectByTag(
    tagNames: string[]
  ): Observable<TagsApi.Tag> {
    return this.http
      .post<TagsApi.Tag>('/tag/GetSynonymsTagsSelectByTag', tagNames)
      .pipe(
        catchError((error) =>
          throwError(
            this.createDgError({
              error,
              message: this.translateService.instant('TagsSvc_GetTagError'),
            })
          )
        )
      );
  }

  /**
   * Convert comma delimited list or array of tags into
   * array of objects with `label` and `url` properties
   *
   * @param tagList
   */
  public objectifyTags(
    tagList: string | Partial<TagsApi.Tag>[]
  ): Partial<{ label: string; url: string }>[] {
    if (!tagList) {
      return [];
    }
    if (typeof tagList === 'string') {
      tagList = tagList.split(',') as Partial<TagsApi.Tag>[];
    }
    function formatTag(tag: string): string {
      return tag.trim().replace('&amp;', '&');
    }
    return tagList
      .map((tag) =>
        typeof tag === 'string'
          ? { name: tag, title: tag }
          : { ...tag, title: tag.title || tag.name }
      )
      .map((tag) => ({
        label: formatTag(tag.title),
        url: encodeURIComponent(formatTag(tag.name)),
      }));
  }

  private updateUserTagsModified(tag: TagsApi.TagDetails): void {
    tag.isFocused = !tag.isFocused;
    tag.requestingUserIsFocused = !tag.requestingUserIsFocused;
    this.notifyUserTagsModified(tag);
    this.tagRatingTrackerService.trackSkillEngagementUpdated(
      TagRatingSkillEngagementType.focus,
      tag.isFocused,
      { ...camelCaseKeys(tag), isFollowing: true } // will always be following skill if this is triggered
    );
  }

  /**
   * Replace the user interest focus tag item
   *
   * @param oldTag
   * @param newTag
   */
  private replaceUserInterestFocus(
    oldTag: TagsApi.TagDetails,
    newTag: TagsApi.TagDetails
  ): Observable<void> {
    return this.http
      .post<void>('/user/ReplaceUserInterestFocus', {
        newTag,
        oldTag,
      })
      .pipe(
        tap(() => {
          this.notifierService.showSuccess(
            this.translateService.instant('TagsSvc_AddFocusSkillSuccess')
          );
          this.updateUserTagsModified(newTag);
        })
      );
  }

  /**
   * Create a DgError to be thrown and caught by angulars global error handler
   */
  private createDgError({
    error,
    message,
  }: {
    error: Error;
    message: string;
  }): DgError {
    return new DgError(message, error);
  }

  /**
   * Map levels array to an object for easy parsing
   *
   * [ 'level 1', 'level 2' ] => { 1: 'level 1', 2: 'level 2' }
   */
  private transformRatingLevelNames(levelNames: string[]): {
    [key: number]: string;
  } {
    return levelNames.reduce((acc, curr, index) => {
      acc[index + 1] = curr;
      return acc;
    }, {});
  }
}
