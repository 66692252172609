import { InferredLevelsService } from './../../../../services/inferred-levels.service';
import { ArticleTrackerService } from './../article-tracker.service';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { readFirst } from '@dg/shared-rxjs';
import { lastValueFrom } from 'rxjs';

// services
import {
  AuthService,
  LDFlagsService,
  TrackerService,
} from '@app/shared/services';
import { InputsService } from '@app/inputs/services/inputs.service';
import { TranslateService } from '@ngx-translate/core';
import { ArticleFacadeBase } from '../article-facade-base.service';
import { CHUploadService } from '@degreed/content-hosting-data-access';
import { ContentHostingUploadAdapterService } from '@app/uploader/upload-section/adapters/content-hosting-upload-adapter.service';
import { ArticleMapperService } from '../article-mapper.service';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import { InputImageUploadAdapterService } from '@app/uploader/upload-section/adapters/input-image-upload-adapter.service';
import { ArticleService } from './../article.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { getTitlesInferredSkills } from '@app/user-content/user-input-v2/utils/inferred-skills-helper';

// misc
import { InputIdentifier } from '@app/inputs/inputs-api.model';
import { MediaParseType } from '@app/shared/models/core.enums';
import { MediaMetadataStatus } from '@app/user-content/user-input/media-modal/media-modal-facade-base';
import {
  ArticleModel,
  ArticleApiInput,
} from '@app/user-content/user-input-v2/inputs/article/article.model';
import {
  InputContext,
  SkillLevelProficiencyParams,
} from '@app/user-content/user-input-v2/input.model';
import { AuthUser } from '@app/account/account-api.model';
import { RenderMode } from '@app/user-content/user-input/user-input.model';
import { getHostedContentDetails } from '@app/user-content/user-input-v2/inputs/article/services/article.utils';
import { DgError } from '@app/shared/models/dg-error';
import { ArticleNotificationService } from '../article-notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { onFormControlUpdate } from '@app/shared/utils/angular-form-field-helpers';
import { GlobalAddTrackingService } from '@app/global-add/services/global-add-tracking.service';
import { useAIInferredSkillProficiencyLevels } from '@app/tags/utils/skills-proficiency-level.utils';
import { TAG_ORIGIN_TYPE } from '@app/tags/tags';

@Injectable({ providedIn: 'root' })
export class CatalogArticleInputFacade extends ArticleFacadeBase {
  // Public properties
  public exclusionList: InputIdentifier[] = [];
  public i18n = this.translate.instant([
    'Core_Completed',
    'Core_1Minute',
    'Core_1Hour',
  ]);

  // Private properties

  constructor(
    public translate: TranslateService,
    public inputsService: InputsService,
    public authService: AuthService,
    public mapperService: ArticleMapperService,
    public orgInternalContentService: OrgInternalContentService,
    public inputImageUploadAdapterService: InputImageUploadAdapterService,
    public articleService: ArticleService,
    public globalAddTrackingService: GlobalAddTrackingService,
    private tracker: TrackerService,
    private contentHostingUploadService: CHUploadService,
    private contentHostingUploadAdapterService: ContentHostingUploadAdapterService,
    private articleNotificationService: ArticleNotificationService,
    private articleTrackerService: ArticleTrackerService,
    private webEnvironmentService: WebEnvironmentService,
    private inferredLevelsService: InferredLevelsService,
    private ldFlagService: LDFlagsService
  ) {
    super(
      inputsService,
      authService,
      translate,
      mapperService,
      orgInternalContentService,
      inputImageUploadAdapterService,
      articleService,
      globalAddTrackingService
    );
  }
  // *******************************************************
  // Getters
  // *******************************************************
  /**
   * Easy access to current snapshot of [read-only] ArticleModel
   * ...
   */
  public get snapshot(): ArticleModel {
    return readFirst(this.viewModel$);
  }

  // *******************************************************
  // Overwrites
  // *******************************************************

  /**
   * Overwrite the on Next form the base facade for catalog
   * When the next button has been selected
   * 1. update the view model with the  url
   * 2.  Check and fetch any duplicates for the url
   * 3. set the status
   * 4. parse the url to get any data we can from it
   * 6. update the status
   * 7. Update the initial mode
   * @param url
   * @returns
   */
  public async onNext(url: string): Promise<void> {
    if (!!url) {
      this.mediaMetadataStatus$.next(MediaMetadataStatus.Parsing);
      // 1. update the view model with the  url
      this.viewModel = { ...this.viewModel, entryUrl: url };
      // 2. Check and fetch any duplicates for the url
      // Note: We are going to continue on to the full form page even with a duplicate
      // https://degreedjira.atlassian.net/browse/PD-92403
      try {
        await this.fetchUrlDuplicates(url);
        // 3.  Parse url (diffbot)
        const result: ArticleApiInput =
          await this.inputsService.getMediaMetadataAsPromise(
            url,
            this.viewModel.inputContext.inputType,
            MediaParseType.None
          );

        // 4. Update the viewModel with the results
        const updatedView = this.mapperService.toViewModel(
          result,
          this.viewModel
        );

        this.viewModel = {
          ...this.viewModel,
          ...updatedView,
          isInitialForm: false,
          organizationId: this.orgId,
          owner: undefined,
        };
        // If the SPL levels is enabled and there are inferred skills,
        // then call the API to get the SPL levels from AI.
        // It is required to send in the skills returned from data science model as well as
        // title/description/url from the the diffbot API.

        if (
          useAIInferredSkillProficiencyLevels(
            this.authService.authUser,
            this.ldFlagService
          ) &&
          (this.viewModel.highConfidenceInferredSkills?.length > 0 ||
            this.viewModel.mediumConfidenceInferredSkills?.length > 0)
        ) {
          const params: SkillLevelProficiencyParams = {
            highLevelskills: getTitlesInferredSkills(
              this.viewModel.highConfidenceInferredSkills
            ),
            mediumLevelskills: getTitlesInferredSkills(
              this.viewModel.mediumConfidenceInferredSkills
            ),
            contentTitle: this.viewModel.title,
            contentDescription: this.viewModel.summary,
            contentUrl: this.viewModel.entryUrl,
          };

          const inferredSkillLevels: any =
            await this.inferredLevelsService.getInferredSkillLevels(params);
          this.viewModel = {
            ...this.viewModel,
            highConfidenceInferredSkills: inferredSkillLevels.highSkillLevels,
            mediumConfidenceInferredSkills:
              inferredSkillLevels.mediumSkillLevels,
          };
        }

        this.mediaMetadataStatus$.next(MediaMetadataStatus.FullyParsed);
        return;
      } catch (e) {
        this.mediaMetadataStatus$.next(MediaMetadataStatus.None);
        throw e;
      }
    }

    this.viewModel = {
      ...this.viewModel,
      isInitialForm: false,
      owner: undefined,
    };
    return;
  }

  public async onSubmit(form: FormGroup): Promise<void> {
    try {
      if (form.value.entryUrl) {
        await this.fetchUrlDuplicates(form.value.entryUrl);
      }
      await super.onSubmit(form);
      this.performSuccessSideEffects();
    } catch (e) {
      throw e;
    }
    return;
  }

  public async initializeEdit(): Promise<void> {
    await super.initializeEdit();

    if (this.viewModel.tags?.length > 0) {
      this.viewModel = {
        ...this.viewModel,
        tags: this.viewModel.tags.map((skill) => ({
          ...skill,
          isAIInferredSkill:
            skill.tagOriginType === TAG_ORIGIN_TYPE.INFERRED_SKILL,
        })),
      };
    }
  }

  /**
   * Override initializeViewModel
   * @param inputContext
   */
  public async initializeViewModel(inputContext: InputContext): Promise<void> {
    await super.initializeViewModel(inputContext);

    // initialize new/computed Properties
    this.viewModel = {
      ...this.viewModel,
      contentUpload: {
        // Content uploader events
        onContentFileChange: this.onContentFileChange.bind(this),
        onContentUploadSuccess: this.onContentUploadSuccess.bind(this),
        onContentDelete: this.onContentDelete.bind(this),
        onContentUploadFailure: this.onContentUploadFailure.bind(this),
      },
      addToCatalog: true, // always true since we are in catalog
      orgContentMetadata: {
        groupIds: [],
        hideFromCatalog: false,
      },
      errorMessages: {
        invalidFileType: this.translate.instant(
          'dgContentHosting_InvalidFileType',
          {
            startAnchor: `<a class="color-blue" target="_blank" href="${this.webEnvironmentService.getZendeskUrl(
              '/articles/4408914250514'
            )}">`,
            endAnchor: '</a>',
          }
        ),
      },
    };

    // Check and initial content hosting properties
    const authUser = this.authService.authUser;
    await this.checkForContentHostingSupport(authUser);
  }

  /** Performs any side effects required following successful creation of an Input */
  protected performSuccessSideEffects() {
    // Profile input creation notification is represented by the completion results presented; pathways hasn't historically provided any
    if (!this.viewModel.inputContext.isEditing) {
      this.articleNotificationService.notifyArticleInputCreated(
        this.viewModel.title
      );

      this.articleTrackerService.trackContentCatalogAdd(this.viewModel);
      return;
    }

    const apiParameters = this.mapperService.toApiParameters(
      this.viewModel as ArticleModel
    );

    this.articleNotificationService.notifyArticleInputUpdated();
    this.articleTrackerService.trackContentCatalogUpdate(apiParameters);
  }

  /** Performs any side effects required following failed creation of an Input */
  protected performFailureSideEffects(error?: HttpErrorResponse) {
    if (!this.viewModel.inputContext.isEditing) {
      this.articleNotificationService.notifyArticleInputCreateFailed();
      return;
    }
    this.articleNotificationService.notifyContentCatalogInputUpdateFailed(
      error
    );
  }

  /********************************************************
   * Content hosting
   ********************************************************/
  /**
   * Check if we can display the content hosting uploader
   * @param authUser
   */
  protected async checkForContentHostingSupport(authUser: AuthUser) {
    const request$ = this.contentHostingUploadService.canUploadHostedFile({
      uploadType: 'Article',
      renderMode: this.viewModel.inputContext.renderMode,
    });
    let response;
    try {
      response = await lastValueFrom(request$);
    } catch (e) {
      throw new DgError(this.translate.instant('InputsSvc_GeneralError'));
    }
    const [supportsContentHosting, fileRestrictions] = response;

    this.viewModel = {
      ...this.viewModel,
      // determines to load upload-section component in modal container
      shouldShowContentUploader:
        supportsContentHosting ||
        (!authUser.canUploadContentFiles &&
          this.viewModel.inputContext.isEditing &&
          this.viewModel.inputContext.renderMode === RenderMode.ContentCatalog),
      // the upload-section component should be displayed but disabled
      shouldDisableContentUploader:
        !supportsContentHosting &&
        this.viewModel.inputContext.renderMode === RenderMode.ContentCatalog &&
        this.viewModel.inputContext.isEditing,
      fileRestrictions: fileRestrictions,
      uploadAdapter: this.contentHostingUploadAdapterService.getAdapter(
        'Article',
        this.viewModel.inputId
      ),
    };
  }

  protected onContentUploadSuccess(
    formGroup: FormGroup,
    fieldName: string,
    response: any
  ) {
    onFormControlUpdate(formGroup, fieldName, response);
    const details = getHostedContentDetails(response);
    if (!details) {
      throw new DgError(
        this.translate.instant('dgFileUploadButton_UploadErrorMessage')
      );
    }
    super.onContentUploadSuccess(formGroup, fieldName, response);

    this.viewModel = {
      ...this.viewModel,
      isSubmitButtonDisabled: false,
      hostedContentDetails: details,
    };
    this.tracker.trackEventData({
      action: 'File Uploaded',
      properties: {
        location: 'Manage Org File Upload',
        contentType: 'Article',
        contentId: this.viewModel.inputId,
        fileType: this.viewModel.hostedContentDetails.fileType,
        filesize: this.viewModel.hostedContentDetails.fileSize,
      },
    });
  }

  public onContentDelete(formGroup: FormGroup) {
    this.viewModel = {
      ...this.viewModel,
      hostedContentDetails: null,
    };
    formGroup.patchValue({
      contentUploader: '',
    });
  }

  /*************************************************************
   * PRIVATE utils
   *************************************************************/
}
