import { CommonModule, DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { take } from 'rxjs/operators';

import {
  animate,
  AnimationEvent,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { isKey, Key } from '@app/shared/key';
import { A11yService } from '@app/shared/services/a11y.service';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import {
  ClosePopoverResult,
  Popover,
  PopoverService,
} from '@app/shared/services/popover.service';
import { Positioning } from '@app/shared/services/positioning.service';
import {
  Placement,
  PlacementArray,
} from '@ng-bootstrap/ng-bootstrap/util/positioning';

const animationTiming = '400ms cubic-bezier(0.68, -0.55, 0.265, 1.55)'; // these settings came from Wouter, do not change without running it by him first

/**
 * A popover with custom content. You can put whatever you like in it and manage/monitor closure and any selected results
 * via the @see PopoverService.
 *
 * **This is a *lightweight alternative* to using `ngbPopover`**, and only listens for click events on the popover trigger.
 * If you need the popover to open on mouseover or focus, consider using `ngbPopover`; if you only want to open a tooltip
 * that is *styled like* our popovers, you may want to use `ngbTooltip` with the `faux-popover` class instead.
 */
@Component({
  selector: 'dgx-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover-arrow.scss', './popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
  animations: [
    trigger('openClose', [
      /* Opening */
      transition('closed => up', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          animationTiming,
          style({ opacity: 1, transform: 'translateY(0rem)' })
        ),
      ]),
      transition('closed => right', [
        style({ opacity: 0, transform: 'translateX(-1rem)' }),
        animate(
          animationTiming,
          style({ opacity: 1, transform: 'translateX(0rem)' })
        ),
      ]),
      transition('closed => down', [
        style({ opacity: 0, transform: 'translateY(-1rem)' }),
        animate(
          animationTiming,
          style({ opacity: 1, transform: 'translateY(0rem)' })
        ),
      ]),
      transition('closed => left', [
        style({ opacity: 0, transform: 'translateX(1rem)' }),
        animate(
          animationTiming,
          style({ opacity: 1, transform: 'translateX(0rem)' })
        ),
      ]),
      /* Closing */
      transition('up => closed', [
        animate(
          animationTiming,
          style({ opacity: 0, transform: 'translateY(1rem)' })
        ),
      ]),
      transition('right => closed', [
        animate(
          animationTiming,
          style({ opacity: 0, transform: 'translateX(-1rem)' })
        ),
      ]),
      transition('down => closed', [
        animate(
          animationTiming,
          style({ opacity: 0, transform: 'translateY(-1rem)' })
        ),
      ]),
      transition('left => closed', [
        animate(
          animationTiming,
          style({ opacity: 0, transform: 'translateX(1rem)' })
        ),
      ]),
    ]),
  ],
})
export class PopoverComponent implements Popover, OnDestroy, OnChanges {
  @Input() public isOpen: boolean;
  @Input() public popoverTrigger: ElementRef;
  @Input() public placement: Placement | PlacementArray = 'auto';
  @Input() public placementAdjustLeftRem: number = 0;
  @Input() public placementAdjustTopRem: number = 0;
  @Input() public placementOffsetLeftPixels: number = 0;
  @Input() public popoverArrowHidden: boolean = false;
  @Input() public focusFirstItem: boolean = true;
  @Input() public appendToBody: boolean = false;

  public popoverPresent = false;
  public preventRefocus = false;
  public animationState: 'closed' | 'up' | 'right' | 'down' | 'left' = 'closed';

  /** Wrapper to contain the popover when it's appended to the `body` element */
  public bodyContainer: HTMLElement | null = null;

  @ViewChild('popover') public popover: ElementRef<HTMLElement>;
  @ViewChild('arrow') public arrow: ElementRef<HTMLElement>;

  /** Fires when the popover open or close animation begins. In most cases, this is the right event to listen to to monitor popover state.  */
  @Output()
  public isOpenChange = new EventEmitter<boolean>();
  /** Fires when the popover open or close animation ends. This is mainly provided for managing focus transitions or other accessibility state.  */
  @Output() public transitionComplete = new EventEmitter<boolean>();
  @Output() public result = new EventEmitter<ClosePopoverResult>();
  private _isOpen = false;
  private isAnimating = false;
  private unlisteners: (() => void)[] = [];

  constructor(
    private popoverService: PopoverService,
    private focusStackService: FocusStackService,
    private renderer: Renderer2,
    private positioningService: Positioning,
    private elementRef: ElementRef,
    private a11yService: A11yService,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public ngOnChanges({
    isOpen,
    placementOffsetLeftPixels,
  }: SimpleChanges): void {
    if (!this.isAnimating) {
      if (isOpen?.currentValue) {
        if (!!isOpen.currentValue !== this._isOpen) {
          this.animateOpen();
        }
      } else {
        if (!!isOpen?.currentValue !== this._isOpen) {
          this.triggerClose();
        }
      }
    }

    // used to reposition popover if outside of viewport
    if (
      placementOffsetLeftPixels?.currentValue !==
      placementOffsetLeftPixels?.previousValue
    ) {
      this.animateOpen();
    }
  }

  public toggle() {
    if (!this.isAnimating) {
      if (!this._isOpen) {
        this.isOpen = true; // keep binding in sync
        this.animateOpen();
      } else {
        this.isOpen = false; // keep binding in sync
        this.triggerClose();
      }
    }
  }

  public ngOnDestroy() {
    if (this.isOpen) {
      this.isOpen = false;
      this.popoverService.close(); // clears out activePopover
      this.removeContainerFromBody();
    }
    this.removeListeners();
  }

  public onAnimationEvent(event: AnimationEvent) {
    // Fires after the open/close animation transitions are complete.
    // The animation is in the 'void' state briefly while the popover placement is determined and then moves to the 'closed state where it begins animating using the transitions defined above. The transition used is based on the placement.

    /* after opening */
    if (event.fromState === 'closed' && event.toState !== 'void') {
      // If `isOpen` is immediately changed back to false make sure popover closes
      // This can happen when a cursor moves rapidly over a hover event trigger
      if (!this.isOpen) {
        this.focusStackService.clear();
        this.triggerClose();
      } else {
        // track where focus should go after popover closes.
        // this can be overriden in the SimpleItemViewModel
        this.focusStackService.push(this.popoverTrigger.nativeElement);

        if (this.focusFirstItem) {
          this.a11yService.focusNextFocusable(this.popover.nativeElement);
        }

        // Listen for closure triggering events only when popover is open
        // The @HostListener mechanism is more convenient, but not efficient for this case.
        this.unlisteners.push(
          this.renderer.listen('document', 'click', ($event: MouseEvent) => {
            return this.onDocumentClick($event);
          })
        );
        this.unlisteners.push(
          this.renderer.listen('document', 'keyup', (ev) => {
            return this.onDocumentKeyUp(ev);
          })
        );

        this.updateTriggerAriaExpanded('true');

        this.isAnimating = false;
        this.transitionComplete.emit(true);
      }
    }

    /* after closing */
    if (event.fromState !== 'void' && event.toState === 'closed') {
      this.resetContainer();

      this.popoverPresent = false; // remove the popover once it's animated closed

      // The popover service currently only supports a single popover open at a time (opening one closes all open)
      // If there _is_ another popover open, there won't be a top item in the current focus stack at this point.
      // see popover.service for more details
      if (this.focusStackService.top && !this.preventRefocus) {
        this.focusStackService.pop();
      }
      this.preventRefocus = false; // reset for next time

      this.updateTriggerAriaExpanded('false');

      this.cdr.detectChanges();
      this.isAnimating = false;
      this.transitionComplete.emit(false);
    }
  }

  private updateTriggerAriaExpanded(isExpanded: string) {
    const popoverTriggerEl = this.popoverTrigger.nativeElement;
    this.renderer.setAttribute(popoverTriggerEl, 'aria-expanded', isExpanded);
  }

  private animateOpen() {
    this._isOpen = true;

    // tracks the current popover as being open so that it can be closed by the popoverService if another popover gets opened
    this.popoverService.openPopover = this;

    // popoverService.close$ resolves after popoverService does its thing to prevent more than one popover being open at the same time. All calls to popoverService.close() in this component end up here eventually, but so does the case where a new popover gets opened causing the previous to be closed.
    this.popoverService.close$
      .pipe(take(1))
      .subscribe((result: ClosePopoverResult) => {
        if (this._isOpen) {
          this.result.emit(result);
          this.preventRefocus =
            result?.itemViewModel?.preventRefocus ||
            result?.preventRefocus ||
            false;
          this.animateClose();
        }
      });

    this.isOpenChange.emit(true);

    // bring popover element in to DOM for positioning
    this.isAnimating = true;
    this.popoverPresent = true;
    // Do the rest on the next tick so the popover is present in the DOM
    this.cdr.detectChanges();

    if (!this.popoverTrigger) {
      console.error(
        'No popoverTrigger provided! Use dgxPopoverTrigger directive or supply an elementRef.nativeElement as a popoverTrigger Input()'
      );
      return;
    }

    // append to body handling (prior to placement being set but after trigger is found to be present)
    this.applyContainer();

    const popoverTriggerEl = this.popoverTrigger.nativeElement;
    // `bodyContainer` is null by default, but set to a <div> when `appendToBody` is true
    const targetElement = this.bodyContainer || this.elementRef.nativeElement;
    this.placement = this.positioningService.positionElement(
      popoverTriggerEl, // required for positioning relative to the trigger
      targetElement,
      this.placement,
      this.placementOffsetLeftPixels,
      this.appendToBody
    );

    // begin animation now that placement is determined
    const dir = this.positioningService.getDirectionFromPlacement(
      this.placement as Placement
    );
    this.animationState = dir;
    this.cdr.detectChanges();
  }

  private triggerClose() {
    // Always use this method to close to notify listeners and update state
    if (this._isOpen) {
      this.popoverService.close();
    }
  }

  private animateClose() {
    this._isOpen = false;
    this.isOpenChange.emit(false);

    this.removeListeners();

    if (this.popover?.nativeElement) {
      // begin animation
      this.isAnimating = true;
      this.animationState = 'closed';
      this.cdr.detectChanges();
    }
  }

  private onDocumentClick(event: MouseEvent) {
    // Click anywhere outside the popover closes after initial open click (or programmatic open)
    if (this._isOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.focusStackService.clear();
      this.triggerClose();
    }
  }

  private onDocumentKeyUp(ev: KeyboardEvent) {
    if (this._isOpen && isKey(ev, Key.Escape)) {
      this.triggerClose();
    }
  }

  private removeListeners() {
    for (const unlisten of this.unlisteners) {
      unlisten();
    }
    this.unlisteners = [];
  }

  /**
   * Append the popover to the body element
   * Stolen from ngBootstrap: https://github.com/ng-bootstrap/ng-bootstrap/blob/ea59f8829f099801df34f15b77a9e06a51f2a110/src/dropdown/dropdown.ts
   */
  private applyContainer() {
    if (this.appendToBody) {
      this.resetContainer();
      const renderer = this.renderer;
      const dropdownMenuElement = this.popover.nativeElement;
      const bodyContainer = (this.bodyContainer =
        this.bodyContainer || renderer.createElement('div'));
      bodyContainer.id = 'popoverBodyContainer';

      renderer.appendChild(bodyContainer, dropdownMenuElement);
      renderer.appendChild(this.document.body, bodyContainer);
    }
  }

  /**
   * Remove the popover from the body element
   * Stolen from ngBootstrap: https://github.com/ng-bootstrap/ng-bootstrap/blob/ea59f8829f099801df34f15b77a9e06a51f2a110/src/dropdown/dropdown.ts
   */
  private resetContainer() {
    if (this.appendToBody) {
      const renderer = this.renderer;
      const menuElement = this.popover;
      if (menuElement) {
        const dropdownElement = this.elementRef.nativeElement;
        const dropdownMenuElement = menuElement.nativeElement;
        renderer.appendChild(dropdownElement, dropdownMenuElement);
      }
      this.removeContainerFromBody();
    }
  }

  // Remove content that is appended to the body
  private removeContainerFromBody() {
    if (this.appendToBody && this.bodyContainer) {
      this.renderer.removeChild(this.document.body, this.bodyContainer);
      this.bodyContainer = null;
    }
  }
}
