import { Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  SkillLevelProficiencyParams,
  SkillLevelProficiencyResponse,
} from '../input.model';
import { TAG_ORIGIN_TYPE } from '@app/tags/tags';
import { TranslateService } from '@ngx-translate/core';
import { DgError } from '@app/shared/models/dg-error';

@Injectable({
  providedIn: 'root',
})
export class InferredLevelsService {
  constructor(
    private http: NgxHttpClient,
    private translate: TranslateService
  ) {}

  /**
   * Get the inferred skill levels for the content
   * @param params
   * @returns
   */
  public getInferredSkillLevels(
    params: SkillLevelProficiencyParams
  ): Promise<any> {
    const request$ = this.http
      .post<SkillLevelProficiencyResponse>(
        '/skills/skillproficiency/getskilllevelsforcontent',
        {
          ...params,
        }
      )
      .pipe(
        map((data) => ({
          ...data,
          highSkillLevels: data.highSkillLevels.map((skill) => ({
            ...skill,
            title: skill.skillName,
            name: skill.skillName,
            isAIInferredSkill: true, // This will add the sparkle icon for tags
            tagOriginType: TAG_ORIGIN_TYPE.INFERRED_SKILL,
          })),
          mediumSkillLevels: data.mediumSkillLevels.map((skill) => ({
            ...skill,
            title: skill.skillName,
            name: skill.skillName,
            isAIInferredSkill: true,
            tagOriginType: TAG_ORIGIN_TYPE.INFERRED_SKILL,
          })),
        })),
        catchError((e) => {
          throw new DgError(
            this.translate.instant('InputsSvc_GeneralError'),
            e
          );
        })
      );

    return lastValueFrom(request$);
  }
}
