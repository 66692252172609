import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { camelCaseKeys } from '@app/shared/utils/property';

import { TagsApi } from '@app/tags/tag-api.model';
import { TagsSearchItem } from '@app/tags/tags';
import { Proficiency } from '@app/skills-platform-lxp-data-access/skill-proficiency/skill-proficiency.model';
import { TagsService } from '@app/tags/services/tags.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'dgx-tags-edit',
  templateUrl: './tags-edit.component.html',
  styles: [':host { display: block; }'],
})
export class TagsEditComponent implements OnInit, OnChanges {
  @Input() public useNewTagsDesign?: boolean = false;
  @Input() public completing: boolean;
  /** Custom tooltip text. *Must already be translated.* */
  @Input() public customTagToolTip: string;
  @Input() public customTitle: string;
  @Input() public tags: TagsApi.Tag[];
  @Input() public showFullWidthLabel?: boolean = false;
  @Input() public targetLevel: number;
  @Input() public hideLabel: boolean;
  @Input() public topTags: TagsApi.Tag[] = [];
  @Input() public hideTopTags: boolean;
  @Input() public hideTooltip: boolean;
  @Input() public tooltipIcon?: string;
  @Input() public hint: string;
  @Input() public hideHint: boolean = false;
  @Input() public required: boolean;
  @Input() public showHintAlone: boolean;
  @Input() public suggestSkills?: boolean;
  @Input() public useSuggestedSkillsView: boolean = false;
  @Input() public loadingJobRoleAISkillSuggestions: boolean = false;
  /** Display proficiency levels on skill search and selected skills  */
  @Input() public useSkillProficiencyLevels: boolean = false;
  /** Custom placeholder text. *Must already be translated.* */
  @Input() public placeholder: string;
  /** If true, only tags that already exist in the org can be added to this display. */
  @Input() public allowExistingOnly = false;
  /** When a tag is added. Emits the added tag. */
  @Output() public addTag: EventEmitter<TagsApi.Tag> =
    new EventEmitter<TagsApi.Tag>();
  /** When a tag is removed. Emits the removed tag. */
  @Output() public removeTag: EventEmitter<Partial<TagsApi.Tag>> =
    new EventEmitter<Partial<TagsApi.Tag>>();
  /** When tags are added *or* removed. Emits whole tag array. */
  @Output() public tagsChange: EventEmitter<TagsApi.Tag[]> = new EventEmitter<
    TagsApi.Tag[]
  >();

  public proficiencyLevels$: Observable<Proficiency>;
  public tagToolTip: string;
  public i18n = this.translate.instant([
    'TagsEdit_AddCategories',
    'TagsEdit_CategoriesMessage',
    'TagsCtrl_CategoryHint',
    'TagsCtrl_Category',
    'TagsCtrl_SkillsPlaceholder',
    'dgTagsEdit_WhatDidYouLearn',
  ]);

  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private tagsService: TagsService
  ) {}

  public get label() {
    return this.completing
      ? this.i18n.dgTagsEdit_WhatDidYouLearn
      : this.customTitle || this.i18n.TagsEdit_AddCategories;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.tags) {
      this.tags = camelCaseKeys(changes.tags.currentValue);
    }
  }

  public ngOnInit() {
    if (this.useSkillProficiencyLevels) {
      this.proficiencyLevels$ = this.tagsService.getOrgProficiencyLevels();
    }
    // some components are still passing keys PascalCased and need to be fixed before we can assume they are CamelCased
    this.tags = camelCaseKeys(this.tags);

    if (!this.hideTopTags) {
      this.topTags = this.topTags?.length
        ? this.topTags
        : this.authService.authUser.viewerInterests;
    }
    this.hint ??= this.hideHint ? null : this.i18n.TagsCtrl_CategoryHint;
    this.allowExistingOnly ??= false;
    this.placeholder ??= this.i18n.TagsCtrl_SkillsPlaceholder;
    this.tagToolTip = !this.hideTooltip
      ? this.customTagToolTip
        ? this.customTagToolTip
        : this.i18n.TagsEdit_CategoriesMessage
      : undefined;
  }

  public onAddTag(tag: Partial<TagsApi.Tag> | TagsSearchItem): void {
    // don't add tags that don't already exist in the organization
    if (
      this.allowExistingOnly &&
      !(tag && ((tag as TagsApi.Tag).tagId || (tag as TagsSearchItem).id))
    ) {
      return;
    }

    if (!this.tags) {
      this.tags = [];
    }

    if (this.useSkillProficiencyLevels && tag) {
      // if the newly added tag already exists in the array, but with a different level
      // replace the tag with the newly added tag and/or no level
      const tagIndex = this.tags.findIndex(
        (t) =>
          (t.tagId === (tag as TagsSearchItem).id ||
            t.tagId === (tag as TagsApi.Tag).tagId ||
            (t as TagsSearchItem).id === (tag as TagsSearchItem).id ||
            (t as TagsSearchItem).id === (tag as TagsApi.Tag).tagId) &&
          t.name === tag.name
      );
      // If the tag does not yet exist in the array, add it
      if (tagIndex === -1) {
        this.tags = [...this.tags, tag as TagsApi.Tag];
        this.addTag.emit(tag as TagsApi.Tag);
      } else if (this.tags[tagIndex].level !== (tag as TagsApi.Tag).level) {
        // IF the tag does exist and the levels are different, replace the level
        this.tags[tagIndex].level = (tag as TagsApi.Tag).level;
        this.addTag.emit(tag as TagsApi.Tag);
      }
    } else if (tag && !this.tags.some((t) => t.name === tag.name)) {
      this.tags = this.tags.concat([tag as TagsApi.Tag]);
      // only emit 'real' tags for this method that haven't already
      // been added to the array.
      this.addTag.emit(tag as TagsApi.Tag);
    }

    this.tagsChange.emit(this.tags);
  }

  public onRemoveTag(tag: Partial<TagsApi.Tag> | TagsSearchItem): void {
    if (!this.tags || !tag) {
      return;
    }

    this.tags = this.tags.filter((t: TagsApi.Tag) => t.name !== tag.name);

    this.removeTag.emit(tag);
    this.tagsChange.emit(this.tags);
  }
}
