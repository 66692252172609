import { Component, Input } from '@angular/core';

@Component({
  selector: 'dgx-data-column-highlight',
  templateUrl: './data-column-highlight.component.html',
  styleUrls: ['./data-column-highlight.component.css'],
  standalone: true,
})
export class DataColumnHighlightComponent {
  @Input() public highlighted: string;
  @Input() public highlightLevel: string;
}
