/**
 * @deprecated
 * use the LineClampDirective or use basic css `.ellipsis` or `.clamp`
 */
import { Pipe, PipeTransform } from '@angular/core';

// TODO: Add implementation for html truncation

@Pipe({ name: 'ellipsis', standalone: true })
export class EllipsisPipe implements PipeTransform {
  constructor() {}

  public transform(input: string, limit: number) {
    if (!input) {
      return '';
    }
    const truncated = input.substring(0, limit);
    if (truncated.length < input.length) {
      return truncated + '...';
    }
    return truncated;
  }
}
