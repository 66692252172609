import { LogTracker, TrackerOptions } from './tracker.utils';

// **********************************************************************
// UnAuthorized Tracking
// **********************************************************************

const blockedGuards_Admin = new LogTracker("Permission Guards", {message:"for Admin navigation", key: "menu", value: "blocked by"});
const blockedGuards_Learner = new LogTracker("Permission Guards", {message:"for Learner navigation", key: "menu", value: "blocked by"});

export type GuardLogType = 'Admin' | 'Learner';

export const showGuardLog = (context: GuardLogType) => {
  const tracker = context === 'Admin' ? blockedGuards_Admin : blockedGuards_Learner
  tracker.report();
};

/**
 * Update node visibility based on parent and child guard permissions
 * NOTE: this will also log any items with blocked (visible = false) access.
 */
export const showWithGuards = (context: GuardLogType) => {
  const tracker = context === 'Admin' ? blockedGuards_Admin : blockedGuards_Learner;
  
  return (
    node: Record<string, any>,
    guards: PermissionGuards
  ) => {
    const isVisible = guards.reduce((result, g) => {
      const numChildren = Object.keys(g.children).length;
      const rootAuthorized = g.permissions && g.settings && g.featureFlags;
      const childNotAuthorized = !g.isAuthorized && rootAuthorized && numChildren;

      if (!g.permissions)     tracker.track(g.name, `permissions`);
      if (!g.settings)        tracker.track(g.name, `settings`);
      if (!g.featureFlags)    tracker.track(g.name, `featureFlags`);
      if (childNotAuthorized) tracker.track(g.name, 'no children authorized');

      // All guards must be authorized, and - for each guard -
      // all permissions, settings, and featureFlags must be authorized

      return result && g.isAuthorized;
    }, true);

    node.visible = isVisible;
  }
};

// **********************************************************************
// Public Types
// **********************************************************************

export type MatchState = boolean | undefined;
export interface GuardItem {
  name: string;
  permissions: boolean;
  settings: boolean;
  featureFlags: boolean;
  isAuthorized: boolean;
  children?: Record<string, GuardItem>;
  child: (id: string) => GuardItem;
  addChildren: (list: GuardItem[]) => GuardItem;
};
export type PermissionGuards = GuardItem[];



// **********************************************************************
// Public Utils
// **********************************************************************

const toCamelCase = (source: string) => source.replace(/^\w/, (c) => c.toUpperCase());

/**
 * Make are GuardItem instance; which may contain optional children
 * Parent permissions will also check for ANY child permissions.
 * 
 * @param name  
 * @param permissionsState  list of user permissions
 * @param settingsState     list of organization settings
 * @param featureFlagsState list of featureFlags
 */
export const makeGuard = (
  name: string,
  permissionsState: MatchState[]  = [],
  settingsState: MatchState[]     = [],
  featureFlagsState: MatchState[] = []
): GuardItem => {
  const evaluate = (targets: MatchState[]): boolean => {
    return targets.reduce((result, p) => {
      return result && !!p;
    }, true);
  };
  const permissions = evaluate(permissionsState);
  const settings = evaluate(settingsState);
  const featureFlags = evaluate(featureFlagsState);
  const isAuthorized = permissions && settings && featureFlags;

  const guard = {
    name: toCamelCase(name),
    permissions,
    settings,
    featureFlags,
    isAuthorized, // allows root menu items to easily check child items
    children: {},
    registry: {},

    /**
     * Child guard accessor (by ID)
     */
    child: (id: string): GuardItem => {
      const source = guard.registry[toCamelCase(id)];
      if (!source) throw new Error(`Uknown child guard ${id} in ${guard.name}`);

      return source;
    },

    /**
     * Child guard registrations
     */
    addChildren: (items: GuardItem[]) => {
      // Track all children (if any)
      const registry: Record<string, GuardItem> = (items || []).reduce(
        (results, it) => {
          const fullName = `${guard.name} > ${toCamelCase(it.name)}`;
          return { ...results, [it.name]: { ...it, name: fullName } };
        },
        {} as Record<string, GuardItem>
      );

      // Do ANY registered children authorize; this allows a parent to authorize ANY child
      const anyChildAuthorized = Object.keys(registry).reduce((authorized, key) => {
        return authorized || registry[key].isAuthorized;
      },false);

      // Update fields
      guard.registry = registry;
      guard.isAuthorized = permissions && settings && featureFlags && anyChildAuthorized;

      return guard;
    },
  };

  return guard;
};
