export type MissingKeys = Record<string, string>;
export type TrackerOptions = { message?: string; key: string; value: string };

export class LogTracker {
  private tracker: Readonly<MissingKeys>[] = [];
  private message = 'keys missing';
  private key = 'i18n';
  private value = 'en';

  constructor(
    private title: string,
    options?: TrackerOptions
  ) {
    if (options) {
      this.key = options.key;
      this.value = options.value;
      this.message = options.message || '';
    }
  }

  track(key: string, value: string) {
    const findEntry = () =>
      this.tracker.reduce((result, it) => {
        result ||= it[this.key] === key ? it : null;
        return result;
      }, null);

    if (!findEntry()) {
      this.tracker.push({
        [this.key]: key,
        [this.value]: value,
      });
    }
  }

  report() {
    if (this.tracker.length) {
      const msg = `${this.title} ${this.message}:`;
      console.warn(msg, this.tracker);
    }
  }
}
