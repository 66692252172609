import { NavigationEnd, Router } from '@angular/router';

import { from, Observable, pipe } from 'rxjs';
import {
  switchMap,
  map,
  tap,
  filter,
  startWith,
  distinctUntilChanged,
} from 'rxjs/operators';

import { produce } from 'immer';
import { LayoutAspect, LayoutConfiguration } from '@degreed/apollo-angular';
import { AuthUser } from '@app/account/account-api.model';

import { updateOrgId } from './layout-configuration.utils';

/**
 *  Select the Admin layout configuration with updated Org Ids
 */
export function selectAdminAspect(authUser: AuthUser, enableLog = false) {
  return pipe(
    map((layout: LayoutConfiguration) => layout.admin),
    updateAdminOrgIds(authUser),
    tap((aspect) => enableLog && console.log(aspect))
  );
}

/**
 * Only allow Learner layout; filter out Admin layout
 */
export function selectLearnerAspect(
  authUser: AuthUser,
  router: Router,
  enableLog = false
) {
  return pipe(
    map((layout: LayoutConfiguration) => layout.learner),
    updateLearnerOrgIds(authUser),
    switchMap(buildLearnerRouteWatch(router)),
    distinctUntilChanged(),
    tap((aspect) => enableLog && console.log(aspect))
  );
}

const updateAdminOrgIds = (authUser: AuthUser) =>
  pipe(map((admin) => produce(admin, (draft) => updateOrgId<LayoutAspect>(draft, authUser, true))));

const updateLearnerOrgIds = (authUser: AuthUser) =>
  pipe(map((learner) => produce(learner, (draft) => updateOrgId<LayoutAspect>(draft, authUser, true))));

/**
 * When route changes (observed by router.events),
 * update the layout configuration to hide/show options
 */
const buildLearnerRouteWatch = (router: Router) => {
  const navigateEnd$ = router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith({ url: window.location.pathname })
  );

  return (learner: LayoutAspect): Observable<LayoutAspect> => {
    return navigateEnd$.pipe(
      map(({ url }: NavigationEnd) => {
        const isSearchVisible = learner.features.search?.visible;
        const showGlobalSearch = !url.includes('/learning');
        const shouldUpdate = isSearchVisible !== showGlobalSearch;

        return produce(learner, (draft) => {
          /**
           * Should we show the Search features in the header area? Since the Search content pages already
           * have their own search bar, we hide the global search bar when viewing the `/learning` pages.
           */
          if (shouldUpdate) {
            draft.features.search.visible = showGlobalSearch;
          }
        });
      })
    );
  };
};
