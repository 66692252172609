import { Inject, Injectable } from '@angular/core';
import { A11yService } from '@app/shared/services/a11y.service';
import { TranslateFn, translateWithDefaults } from '@app/shared/utils';
import { WindowToken } from '@app/shared/window.token';
import { useApolloLayout } from '@degreed/apollo-angular';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrgPageHeaderService {
  public $pageHeader = new ReplaySubject<string>();
  public $windowTitle = new ReplaySubject<string>();
  public $showPageHeader = new ReplaySubject<boolean>();

  private pageHeader: string;
  private windowTitle: string;
  private showPageHeader: boolean;

  private translate: TranslateFn;

  constructor(
    translateService: TranslateService,
    private a11yService: A11yService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    this.translate = translateWithDefaults(translateService);
  }

  public setPageHeader(key: string, fallback = '', updateWindowTitle = false) {
    this.pageHeader = this.translate(fallback, key);
    this.$pageHeader.next(this.pageHeader);

    if (updateWindowTitle) {
      this.setWindowTitle(key, fallback);
    }

    this.setShowPageHeader(true);
  }

  public setWindowTitle(key: string, fallback = '') {
    this.windowTitle = this.translate(fallback, key);
    this.$windowTitle.next(this.windowTitle);

    this.windowRef.document.title = `${this.windowTitle} | Degreed`;
    this.a11yService.announcePolite(
      this.translate('', 'A11y_TabChangeAnnouncement', {
        newPageTitle: this.windowTitle,
      })
    );
  }

  /**
   * When using Apollo, we set the page header with the key and fallback
   * Why? We do this for all pages when using Apollo, so that they have a consistent page title
   * that matches the name of the nav item in the new layout navigation.
   */
  public setApolloHeader(key: string, fallback: string): void {
    if (useApolloLayout()) {
      this.setPageHeader(key, fallback, true);
    }
  }

  public setShowPageHeader(visible: boolean) {
    this.showPageHeader = visible;
    this.$showPageHeader.next(this.showPageHeader);
  }
}
