import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { Predicate } from '@app/automations/model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-predicate-list',
  templateUrl: './predicate-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PredicateListComponent {
  @Input() public predicates: Predicate[];

  public i18n = this.translate.instant(['BusinessRules_And']);

  constructor(private translate: TranslateService) {}

  public trackById(index: number, predicate: Predicate) {
    return predicate.id;
  }
}
