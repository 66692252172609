import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { TrackerService } from '@app/shared/services/tracker.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { ModalService } from '@app/shared/services/modal.service';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';

import { UserSearchItem } from '@app/user/user-api.model';
import { SimpleItemViewModel } from '@app/shared/models/core-view.model';
import { OrgTargetVM } from '@app/target/target-api.model';

import { AddOrgCollaboratorsModalComponent } from '@app/orgs/components/add-collaborators-modal/add-collaborators-modal.component';
import { ChannelBundleSelectModalResponse } from './../../channel/components/channel-bundle-select-modal/channel-bundle-select-modal.component';
import { ChannelBundleSelectModalComponent } from '@app/channel/components/channel-bundle-select-modal/channel-bundle-select-modal.component';
import { ChannelBundleApiService } from '@app/channel/services/channel-bundle-api.service';
import { OrgPlansService } from '@app/orgs/services/org-plans.service';
import {
  EditVisibilityModalService,
  VisibilityData,
} from '@app/orgs/components/edit-visibility-modal/edit-visibility-modal.service';
import { AddEditTargetModal } from '@app/target/components/modals/add-edit-target-modal/add-edit-target-modal.component';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import {
  AddBundleResourceModalComponent,
  AddBundleResourceModalInputs,
} from '@app/channel/components/add-bundle-resource-modal/add-bundle-resource-modal.component';
import { ConfirmMovePlanModalComponent } from '../components/confirm-move-plan-modal/confirm-move-plan-modal.component';
import { SharedTargetService } from '@app/target/services/shared-target.service';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';

@Injectable({
  providedIn: 'root',
})
export class OrgPlansModalService {
  constructor(
    private modalService: ModalService,
    private ldFlagsService: LDFlagsService,
    private translate: TranslateService,
    private channelBundleApiService: ChannelBundleApiService,
    private notifier: NotifierService,
    private sharedTargetService: SharedTargetService,
    private tracker: TrackerService,
    private editVisibilityModalService: EditVisibilityModalService,
    private orgPlansService: OrgPlansService
  ) {}

  /**
   * Shows Add Target Modal.
   * Note: The add target modal handles submission and data creation on the server
   */
  public showAddModal(orgId: number, context: string) {
    const resolve = {
      mode: 'new',
      targetSource: { organizationId: orgId },
      orgId,
      context,
      showAutoPopulateOption: !this.ldFlagsService.hideTargetAutoPopulate,
    };
    const modalOptions = {
      inputs: {
        resolve,
      },
    };

    return this.modalService.show<void>(AddEditTargetModal, modalOptions);
  }

  /**
   * Shows remove modal.  This is used to remove a plan from a channel bundle.
   */
  public showRemoveModal(plans: OrgTargetVM[] = null, bundle: any) {
    const bodyText =
      plans?.length === 1
        ? this.translate.instant('Channel_ContentRemoveConfirmMessageFormat', {
            contentTitle: plans[0].name,
          })
        : this.translate.instant(
            'Channel_ContentRemoveAllConfirmMessageFormat',
            {
              contentCount: plans.length,
              bundleTitle: bundle.title,
            }
          );
    const inputs = {
      bodyText,
      canCancel: true,
      submitButtonText: this.translate.instant('Core_YesSure'),
    };

    return this.modalService.show(SimpleModalComponent, { inputs }).pipe(
      switchMap(() => {
        return this.channelBundleApiService.removeResourcesFromBundle({
          ...bundle,
          resources: plans,
        });
      })
    );
  }

  /**
   * Shows bulk add to bundle modal.  Used to add multiple plans to a bundle(s)
   */
  public showBulkAddToBundleModal(plans: OrgTargetVM[], orgId: number) {
    const resources = plans.map((plan) => {
      return { resourceId: plan.targetId, resourceType: plan.resourceType };
    });

    const resolve = {
      orgId,
      resources,
    };

    const modalOptions = {
      inputs: {
        resolve,
      },
    };
    return this.modalService
      .show(ChannelBundleSelectModalComponent, modalOptions)
      .pipe(
        switchMap(
          ({
            resources,
            selectedBundles,
          }: ChannelBundleSelectModalResponse) => {
            return this.channelBundleApiService
              .addResourcesToBundles(resources, selectedBundles)
              .pipe(
                tap(() => {
                  this.notifier.showSuccess(
                    this.translate.instant(
                      'Channel_SuccessfulAddResourcesToBundle',
                      {
                        itemCount: resources.length,
                        bundleCount: selectedBundles.length,
                      }
                    )
                  );
                })
              );
          }
        )
      );
  }

  /**
   * Shows modal to add content to a specified bundle
   */
  public showAddContentModal(
    bundle,
    resourceList: OrgTargetVM[],
    targetTypes: string[]
  ) {
    const inputs: AddBundleResourceModalInputs = {
      type: 'Target',
      bundle,
      resourceList,
      config: {
        // map to the target type ids
        targetTypes: targetTypes.join(','),
      },
    };

    return this.modalService.show<void>(AddBundleResourceModalComponent, {
      inputs,
    });
  }

  /**
   * Shows modal to move a plan from the content catalog to the global catalog
   */
  public showMoveToGlobalModal(plan: OrgTargetVM) {
    return this.modalService
      .show(ConfirmMovePlanModalComponent, {
        inputs: { plan },
      })
      .pipe(
        switchMap(() => {
          return this.sharedTargetService.moveTargetToChannel(plan.targetId);
        })
      );
  }

  /**
   * Shows modal to clone a plan
   */
  public showCloneModal(plan: OrgTargetVM) {
    const resolve = {
      mode: 'clone',
      targetSource: plan,
      context: 'default',
      loadTarget: true,
    };
    const modalOptions = {
      inputs: {
        resolve,
      },
    };
    return this.modalService.show(AddEditTargetModal, modalOptions);
  }

  /**
   * Shows modal to change plan visibility for multiple plans
   */
  public showBulkVisibilityModal(planIds: number[]) {
    this.tracker.trackEventData({
      action: 'Bulk Change Visibility Initiated Plans',
    });

    const modalOptions = {
      headerText:
        planIds.length < 2
          ? this.translate.instant('PathwayPrivacyModal_BulkChangeVisibility')
          : this.translate.instant(
              'PathwayPrivacyModal_BulkChangeVisibilityPlural',
              {
                count: planIds.length,
              }
            ),
    };

    let privacyChange: Visibility;
    return this.editVisibilityModalService.openModal(modalOptions).pipe(
      switchMap((result: VisibilityData) => {
        privacyChange = result.privacyId;
        return this.orgPlansService.bulkVisibilityChange(
          planIds,
          result.privacyId,
          result.groups ?? []
        );
      }),
      switchMap(() => of(privacyChange))
    );
  }

  /**
   * Shows modal to add new collaborators to multiple plans
   */
  public showBulkCollaboratorsModal(
    planIds: number[]
  ): Observable<{ userKeys: number[]; collaborators: UserSearchItem[] }> {
    this.tracker.trackEventData({
      action: 'Bulk Add Collaborators Initiated Plans',
    });

    const inputs = {
      headerText: this.translate.instant(
        'OrgPathwayManagerCtrl_AddCollaborators'
      ),
      submitButtonText: this.translate.instant('Core_Add'),
      collaborators: [],
    };

    let usersAddedAsCollaborators;

    return this.modalService
      .show(AddOrgCollaboratorsModalComponent, {
        inputs,
      })
      .pipe(
        switchMap(
          (result: { userKeys: number[]; collaborators: UserSearchItem[] }) => {
            usersAddedAsCollaborators = result;
            return this.orgPlansService.bulkAddCollaborators({
              itemIds: planIds,
              toAddUserKeys: result.userKeys,
            });
          }
        ),
        switchMap(() => {
          return of(usersAddedAsCollaborators);
        })
      );
  }

  /**
   * Shows confirmation modal to delete multiple plans at once
   */
  public showBulkDeleteModal(planIds: number[]) {
    this.tracker.trackEventData({ action: 'Bulk Delete Initiated Plans' });

    const deletePhrase = 'DELETE';
    const deleteInstructions = this.translate.instant(
      'TargetCtrl_DeleteInstructions',
      { delete: deletePhrase }
    );
    const title =
      planIds.length > 1
        ? this.translate.instant('OrgPlans_DeletePluralPrompt', {
            count: planIds.length,
          })
        : this.translate.instant('OrgPlans_DeletePrompt');
    const modalOptions = {
      title: title,
      deleteInstructions: deleteInstructions,
      confirmButtonText: this.translate.instant('Core_Confirm'),
      deletePhrase: deletePhrase,
      description: this.translate.instant('TargetCtrl_DeletePromptDescription'),
    };

    return this.modalService.showDeleteConfirmation(modalOptions).pipe(
      switchMap(() => {
        return this.orgPlansService.bulkDeletePlans(planIds);
      })
    );
  }

  /**
   * Shows confirmation modal to delete a single plan
   */
  public showDeleteModal(plan: OrgTargetVM) {
    const deletePhrase = 'DELETE';
    const deleteInstructions = this.translate.instant(
      'TargetCtrl_DeleteInstructions',
      { delete: deletePhrase }
    );
    const description = this.translate.instant('TargetCtrl_DeletePrompt', {
      targetTitle: plan.name,
    });

    return this.modalService
      .showDeleteConfirmation({
        title: '',
        deleteInstructions,
        confirmButtonText: this.translate.instant('Core_Confirm'),
        deletePhrase,
        description,
      })
      .pipe(
        switchMap(() => {
          return this.sharedTargetService.deleteTarget(plan);
        })
      );
  }

  /**
   * Shows Edit Target Modal
   * Note: The edit target modal handles submission and data creation on the server
   */
  public showEditModal(plan: OrgTargetVM, context: string) {
    const resolve = {
      mode: 'edit',
      targetSource: plan,
      orgId: plan.organizationId,
      context,
      loadTarget: true,
      isManagePlans: true,
    };
    const modalOptions = {
      inputs: {
        resolve,
      },
    };
    return this.modalService.show(AddEditTargetModal, modalOptions);
  }
}
