/* eslint-disable @typescript-eslint/no-explicit-any */
import { TranslateService } from '@ngx-translate/core';

import { BrandingState, navigationToAspect } from '@app/orgs/components/org-branding/v3/data-service';
import { OrganizationSupportInfo } from '@app/orgs/services/orgs.model';

import { AuthUser } from '@app/account/account-api.model';
import {
  AuthService,
  ContextService,
  LDFlagsService,
  TrackerService,
  WebEnvironmentService,
} from '@app/shared/services';
import { TeamFlagsService } from '@app/team/services/team-flags.service';

import { LayoutAspect, LayoutConfiguration } from '@degreed/apollo-angular';

import { LogTracker } from './tracker.utils';
import { showGuardLog, showWithGuards } from './permission.guards';
import { LxpConfigOptions_Admin, resolveOrgInfo, Permissions_forAdmin, Permissions_forLearner } from './permission.factory';

const _lostKeys = new LogTracker('i18n warning');


/**
 * For any `i18n` field, replace the associated field with translated value
 * @returns LayoutConfiguration
 */
export function updatei18n(
  configuration: LayoutConfiguration,
  translate: TranslateService
): LayoutConfiguration {
  const MAPPINGS = {
    i18n: 'text',
    headerTitleI18n: 'headerTitle',
    buttonI18n: 'buttonText',
    titleI18n: 'titleText',
  };
  const i18n = i18nWithLog(translate);
  const visitor = (node, key) => {
    // For each node, check if it has an translation field
    switch (key) {
      case 'i18n':
      case 'headerTitleI18n':
      case 'buttonI18n':
      case 'titleI18n':
        {
          const field = MAPPINGS[key];
          node[field] = i18n(node[key], node[field]);
        }
        break;
    }
  };

  configuration = traverse(configuration, visitor);

  // Warn if any i18n keys are missing from the localization bundle!
  _lostKeys.report();

  return configuration;
}


/**
 * Replace all `<orgId>` tokens with url-specific orgId or default orgId
 * This can be called at app initialization or at any time the user navigates to a new org
 * 
 * @param configuration T = LayoutConfiguration | LayoutAspect
 * @param user AuthUser
 */
export function updateOrgId<T = LayoutConfiguration>(
  configuration: T,
  user: AuthUser | undefined,
  forceUpdate = false
): T {
  // Determine which org the user has access
  const [isOrgView, orgId] = resolveOrgInfo(user);
  if (isOrgView || forceUpdate) {    
    const visitLinks = (node, key) => {
      switch (key) {
        case 'href':
        case 'routerLink':
          {
            const text: string = node[key] || '';
            if (text?.includes('<orgId>')) {
              node[key] = text.replace('<orgId>', orgId);
            }
          }
          break;
      }
    };
  
    // Only do this the url starts with `orgs/:id`
    configuration = traverse(configuration, visitLinks);
  }

  return configuration;
}

/**
 * For all navigation nodes, set `visible` to true unless explicitly set to false
 */
export function updateVisibility(configuration: LayoutConfiguration | LayoutAspect): LayoutConfiguration | LayoutAspect {
  const isAspect = (configuration: LayoutConfiguration | LayoutAspect): configuration is LayoutAspect => {
    return !configuration['admin'] && !configuration['learner'];
  }
  const visitNavigation = (node) => {
    // Force explicity visibility settings
    if (node.visible !== false) node.visible = true;
  };
  
  if (isAspect(configuration)) {
    traverse(configuration.navigation, visitNavigation);
    traverse(configuration.features, visitNavigation);
  } else {
    traverse(configuration.admin, visitNavigation);
    traverse(configuration.learner, visitNavigation);
  }

  return configuration;
}

export function updateNavigationBranding( config: LayoutConfiguration, branding: Partial<BrandingState> ): LayoutConfiguration {
  if (branding) {
    config.admin = config.admin ? navigationToAspect(branding.navigation, config.admin) : undefined;
    config.learner = config.learner ? navigationToAspect(branding.navigation, config.learner) : undefined;
  }
  return config;
}

/**
 * Replace all `<user>` tokens with current user's vanityUrl
 * Replace profile nav item image with current user's picture
 * @param configuration LayoutConfiguration
 * @param user AuthUser
 */
export function updateUser( configuration: LayoutConfiguration, user: AuthUser | undefined, environment: WebEnvironmentService ): LayoutConfiguration {
  const hasProfile = !!user?.viewerProfile;
  const userName = user?.viewerProfile.vanityUrl || '';
  let profileImage = user?.viewerProfile.picture;

  const visitLinks =  (node: LayoutNode, key: string) => {
    switch (key) {
      case 'href':
      case 'routerLink': {
        const text: string | undefined = node[key];
        if (text?.includes('<user>') && hasProfile) node[key] = text.replace('<user>', userName);
        if (text?.includes('<orgId>')) node[key] = text.replace('<orgId>', user?.defaultOrgId.toString());
        break;
      }
      }
  };
  const visitDGATs = (node: LayoutNode) => {
    switch (node.dgat) {
      case 'global.navigation.learner.sidebar.profile': // Update Profile image
        if (hasProfile && profileImage) {
          if (profileImage.startsWith('~')) {
            profileImage = environment.getBlobUrl(profileImage);
            node.image = profileImage;
          }
        }
        break;
    }
  };

  configuration = traverse(configuration, visitLinks);
  configuration = traverse(configuration, visitDGATs);

  return configuration;
}


/**
 * Update the Degreed "Admin"  (instead of the Learner view) menu item visibility
 * NOTE: Some items require special access based on user permissions, org settings, and feature flags 
 */
export function updateAdminNavigation( configuration: LayoutConfiguration, options: LxpConfigOptions_Admin ) {
  const checkPermissions = showWithGuards('Admin')
  const {
    insights, people, catalog, 
    skills, marketPlace, automations, 
    reporting, integrations, settings, 
    extendedEnterprise } = Permissions_forAdmin(options); 
  
  const visitDGATs = (node: LayoutNode, key: string) => {
    if (key === 'dgat') {
      switch (node.dgat) {
        // Insights (menu):
        case 'global.navigation.admin.sidebar.dashboard':                    checkPermissions( node, [insights ]);                            break;
        case 'global.navigation.admin.sidebar.dashboard.learning':           checkPermissions( node, [insights.child('learning') ]);          break;
        case 'global.navigation.admin.sidebar.dashboard.skills':             checkPermissions( node, [insights.child('skills') ]);            break;
        case 'global.navigation.admin.sidebar.dashboard.skill-trends':       checkPermissions( node, [insights.child('skillTrends') ]);       break;

        // People (menu):
        case 'global.navigation.admin.sidebar.people':                       checkPermissions( node, [people] );                              break;
        case 'global.navigation.admin.sidebar.people.users':                 checkPermissions( node, [people.child('users')] );               break;
        case 'global.navigation.admin.sidebar.people.groups':                checkPermissions( node, [people.child('groups')] );              break;
        case 'global.navigation.admin.sidebar.people.segments':              checkPermissions( node, [people.child('segments')] );            break;
        case 'global.navigation.admin.sidebar.people.user-attributes':       checkPermissions( node, [people.child('manageAttributes')] );    break;
        case 'global.navigation.admin.sidebar.people.permissions':           checkPermissions( node, [people.child('permissions')] );         break;
        // Catalog (menu):
        case 'global.navigation.admin.sidebar.catalog':                      checkPermissions( node, [catalog] );                             break;
        case 'global.navigation.admin.sidebar.catalog.content':              checkPermissions( node, [catalog.child('content')] );            break;
        case 'global.navigation.admin.sidebar.catalog.academies':            checkPermissions( node, [catalog.child('academies')] );          break;  
        case 'global.navigation.admin.sidebar.catalog.pathways':             checkPermissions( node, [catalog.child('pathways')] );           break;  
        case 'global.navigation.admin.sidebar.catalog.plans':                checkPermissions( node, [catalog.child('plans')] );              break;  
        case 'global.navigation.admin.sidebar.catalog.skill-dev':            checkPermissions( node, [catalog.child('skill-dev')] );          break;  

        // Skills (menu):
        case 'global.navigation.admin.sidebar.skills':                       checkPermissions( node, [skills] );                              break;
        case 'global.navigation.admin.sidebar.skills.dashboard':             checkPermissions( node, [skills.child('dashboard')] );           break;
        case 'global.navigation.admin.sidebar.skills.inventory':             checkPermissions( node, [skills.child('inventory')] );           break;
        case 'global.navigation.admin.sidebar.skills.scales':                checkPermissions( node, [skills.child('scales')] );              break;
        case 'global.navigation.admin.sidebar.skills.skill-list':            checkPermissions( node, [skills.child('org-skills')] );          break;
        case 'global.navigation.admin.sidebar.skills.roles':                 checkPermissions( node, [skills.child('roles')] );               break;
        case 'global.navigation.admin.sidebar.skills.skill-standards':       checkPermissions( node, [skills.child('skill-standards')] );     break;
        case 'global.navigation.admin.sidebar.skills.publish':               checkPermissions( node, [skills.child('publish')] );             break;
        case 'global.navigation.admin.sidebar.skills.settings':              checkPermissions( node, [skills.child('settings')] );            break;

        // Content MarketPlace (menu):
        case 'global.navigation.admin.sidebar.content-marketplace':          checkPermissions( node, [marketPlace] );                         break;  

        // Automation (menu):
        case 'global.navigation.admin.sidebar.automations':                  checkPermissions( node, [automations] );                         break;  

        // Reporting (menu):
        case 'global.navigation.admin.sidebar.reporting':                    checkPermissions( node, [reporting] );                           break;
        case 'global.navigation.admin.sidebar.reporting.reports':            checkPermissions( node, [reporting.child('reports')] );          break;
        case 'global.navigation.admin.sidebar.reporting.presets':            checkPermissions( node, [reporting.child('presets')] );          break;
        case 'global.navigation.admin.sidebar.reporting.categories':         checkPermissions( node, [reporting.child('categories')] );       break;
        case 'global.navigation.admin.sidebar.reporting.ftp':                checkPermissions( node, [reporting.child('ftp')] );              break;
        case 'global.navigation.admin.sidebar.reporting.configurations':     checkPermissions( node, [reporting.child('configurations')] );   break;
        case 'global.navigation.admin.sidebar.reporting.segments':           checkPermissions( node, [reporting.child('segments')] );         break;
        case 'global.navigation.admin.sidebar.reporting.advanced-analytics': checkPermissions( node, [reporting.child('analytics')] );        break;

        // Integrations (menu):
        case 'global.navigation.admin.sidebar.integrations':                 checkPermissions( node, [integrations]);                          break;
        case 'global.navigation.admin.sidebar.integrations.connected':       checkPermissions( node, [integrations.child('connected')]);       break;
        case 'global.navigation.admin.sidebar.integrations.directory':       checkPermissions( node, [integrations.child('directory')]);       break;
        case 'global.navigation.admin.sidebar.integrations.api-keys':        checkPermissions( node, [integrations.child('api-keys')]);        break;
        case 'global.navigation.admin.sidebar.integrations.file-upload':     checkPermissions( node, [integrations.child('file-upload')]);     break;
        case 'global.navigation.admin.sidebar.integrations.file-log':        checkPermissions( node, [integrations.child('file-log')]);        break;
        case 'global.navigation.admin.sidebar.integrations.webhooks':        checkPermissions( node, [integrations.child('webhooks')]);        break;

        // Settings (menu):
        case 'global.navigation.admin.sidebar.settings':                     checkPermissions( node, [settings] );                             break;
        case 'global.navigation.admin.sidebar.settings.branding':            checkPermissions( node, [settings.child('branding')]);            break;
        case 'global.navigation.admin.sidebar.settings.home':                checkPermissions( node, [settings.child('home')]);                break;
        case 'global.navigation.admin.sidebar.settings.help-menu':           checkPermissions( node, [settings.child('help-menu')]);           break;
        case 'global.navigation.admin.sidebar.settings.communication':       checkPermissions( node, [settings.child('communication')]);       break;
        case 'global.navigation.admin.sidebar.settings.faq':                 checkPermissions( node, [settings.child('faq')]);                 break;
        case 'global.navigation.admin.sidebar.settings.messaging':           checkPermissions( node, [settings.child('messaging')]);           break;
        case 'global.navigation.admin.sidebar.settings.security':            checkPermissions( node, [settings.child('security')]);            break;

        // Extended Enterprise (menu):
        case 'global.navigation.admin.sidebar.extended-enterprise':          checkPermissions( node, [extendedEnterprise] );                   break;

        // Help (menu):
        case 'global.navigation.admin.sidebar.help':                  
        case 'global.navigation.admin.sidebar.help.knowledge-center':   
        case 'global.navigation.admin.sidebar.help.privacy-policy':     
        case 'global.navigation.admin.sidebar.help.cookie-notice':      
        case 'global.navigation.admin.sidebar.help.faq':                
        case 'global.navigation.admin.sidebar.help.custom-link':        
        case 'global.navigation.admin.sidebar.help.support-phone':      
        case 'global.navigation.admin.sidebar.help.support-email':           break;  // No guards
      }
    }
  };

  configuration = traverse(configuration, visitDGATs);
  
  showGuardLog('Admin');

  return configuration;
}

/**
 * Update the navigation items based on feature flags and user permissions
 */
export function updateLearnerNavigation(
  configuration: LayoutConfiguration,
  authUser: AuthUser | undefined,
  featureFlags: LDFlagsService,
  teamFlags: TeamFlagsService,
  auth: AuthService,
  context: ContextService,
) {
  
  const options = {authUser, featureFlags, teamFlags, orgInfo: authUser.defaultOrgInfo, isChannel: context.isChannel() };
  const {
    features, home, featured, 
    discover, skillCoach, assistant, 
    opportunities, notifications, profile
  } = Permissions_forLearner(options);
  const checkPermissions = showWithGuards('Learner')

  const visitDGATs = (node, key) => {
    if (key === 'dgat') {
      switch (node.dgat) {

        // Features 
        case 'global.navigation.learner.features.search':                     break;
        case 'global.navigation.learner.features.add-content':                break;
        case 'global.navigation.learner.features.beta':                       break;
        case 'global.navigation.learner.features.footer-branding':            break;
        case 'global.navigation.learner.features.sidebar-branding':           break;
        case 'global.navigation.learner.features.show-admin-view':            checkPermissions( node, [features.child('show-admin-view')] );        break;

        // Home (menu)
        case 'global.navigation.learner.sidebar.home':                        checkPermissions( node, [home] );                                     break;
        case 'global.navigation.learner.sidebar.home.my-learning':            checkPermissions( node, [home.child('my-learning')] );                break;
        case 'global.navigation.learner.sidebar.home.assignments':            checkPermissions( node, [home.child('assignments')] );                break;
        case 'global.navigation.learner.sidebar.home.saved':                  checkPermissions( node, [home.child('saved')] );                      break;
        case 'global.navigation.learner.sidebar.home.shared':                 checkPermissions( node, [home.child('shared')] );                     break;
        case 'global.navigation.learner.sidebar.home.pathways':               checkPermissions( node, [home.child('pathways')] );                   break;
        case 'global.navigation.learner.sidebar.home.plans':                  checkPermissions( node, [home.child('plans')] );                      break;
        case 'global.navigation.learner.sidebar.home.groups':                 checkPermissions( node, [home.child('groups')] );                     break;

        // Featured (menu)
        case 'global.navigation.learner.sidebar.featured':                    checkPermissions( node, [featured] );                                 break;

        // Discover (menu)
        case 'global.navigation.learner.sidebar.discover':                    checkPermissions( node, [discover] );                                 break;

        // Skill Coach(menu)
        case 'global.navigation.learner.sidebar.skill-coach':                 checkPermissions( node, [skillCoach] );                               break;
        case 'global.navigation.learner.sidebar.skill-coach.members':         checkPermissions( node, [skillCoach.child('members')] );              break;
        case 'global.navigation.learner.sidebar.skill-coach.team-skills':     checkPermissions( node, [skillCoach.child('team-skills')] );          break;
        case 'global.navigation.learner.sidebar.skill-coach.assignments':     checkPermissions( node, [skillCoach.child('assignments')] );          break;
        case 'global.navigation.learner.sidebar.skill-coach.learning-insights': checkPermissions( node, [skillCoach.child('learning-insights')] );  break; 
        case 'global.navigation.learner.sidebar.skill-coach.skill-insights':  checkPermissions( node, [skillCoach.child('skill-insights')] );       break;

        // Degreed Assistant (menu)
        case 'global.navigation.learner.sidebar.assistant':                   checkPermissions( node, [assistant] );                                break;

        // Opportunities (menu)
        case 'global.navigation.learner.sidebar.opportunities':               checkPermissions( node, [opportunities] );                            break;
        case 'global.navigation.learner.sidebar.opportunities.marketplace':   checkPermissions( node, [opportunities.child('marketPlace')] );       break;
        case 'global.navigation.learner.sidebar.opportunities.browse':        checkPermissions( node, [opportunities.child('browse')] );            break;
        case 'global.navigation.learner.sidebar.opportunities.mentoring':     checkPermissions( node, [opportunities.child('mentoring')] );         break;
        case 'global.navigation.learner.sidebar.opportunities.open-insights': checkPermissions( node, [opportunities.child('open-insights')] );     break;
        case 'global.navigation.learner.sidebar.opportunities.engagement-insights': checkPermissions( node, [opportunities.child('engagement-insights')] );   break;

        // Notifications (menu)
        case 'global.navigation.learner.sidebar.notifications':               checkPermissions( node, [notifications] );                            break;

        // Profile (menu)
        case 'global.navigation.learner.sidebar.profile':                     checkPermissions( node, [profile] );                                  break;
        case 'global.navigation.learner.sidebar.profile.overview':            checkPermissions( node, [profile.child('overview')] );                break;
        case 'global.navigation.learner.sidebar.profile.skills':              checkPermissions( node, [profile.child('skills')] );                  break;
        case 'global.navigation.learner.sidebar.profile.collection':          checkPermissions( node, [profile.child('collection')] );              break;
        case 'global.navigation.learner.sidebar.profile.activity':            checkPermissions( node, [profile.child('activity')] );                break;
        case 'global.navigation.learner.sidebar.profile.flex-ed':             checkPermissions( node, [profile.child('flex-ed')] );                 break;
        case 'global.navigation.learner.sidebar.profile.settings':            checkPermissions( node, [profile.child('settings')] );                break;
        case 'global.navigation.learner.sidebar.profile.my-plan':             checkPermissions( node, [profile.child('my-plan')] );                 break;
        case 'global.navigation.learner.sidebar.profile.logout':              checkPermissions( node, [profile.child('logout')] );                  break;

        // Help (menu)
        case 'global.navigation.learner.sidebar.help':                        break;
        case 'global.navigation.learner.sidebar.help.knowledge-center':       break;
        case 'global.navigation.learner.sidebar.help.privacy-policy':         break;
        case 'global.navigation.learner.sidebar.help.cookie-notice':          break;
        case 'global.navigation.learner.sidebar.help.faq':                    break;
        case 'global.navigation.learner.sidebar.help.custom-link':            break;
        case 'global.navigation.learner.sidebar.help.support-phone':          break;
        case 'global.navigation.learner.sidebar.help.support-email':          break;                          
      }
    }
  };

  const visitLinks = (node, key, parent) => {
    const userName = authUser?.viewerProfile.vanityUrl || '';
    const updateLink = (link) => node.routerLink = link;

    if (key === 'dgat') {
      switch (node.dgat) {
        case 'global.navigation.learner.sidebar.home.my-learning':            updateLink(`/${userName}/learnerhub/home`);                         break;
        case 'global.navigation.learner.sidebar.home.assignments':            updateLink(`/${userName}/learnerhub/assignments`);                  break;
      }
    }
  }

  traverse(configuration, visitLinks);
  traverse(configuration, visitDGATs);

  showGuardLog('Learner');

  return configuration;
}


/**
 * Update the navigation items with the correct URLs
 */
export function updateUrls(
  configuration: LayoutConfiguration,
  user: AuthUser,
  featuredPlanId: number,
  learnInSSOUrl: string,
  analyticsUrl: string
) {
  const showFeatured =
    featuredPlanId &&
    !user?.isSkillAnalyticsClient &&
    !user?.isSkillInventoryClient;
  
  const visitor = (node, key, parent) => {
    const showNode = (isVisible: boolean | undefined) => node.visible = !!isVisible;

    const text = () => node[key];
    if (key === 'dgat') {
      switch (node.dgat) {
        case 'global.navigation.learner.sidebar.featured':
          showNode(showFeatured);
          break;
      }
    } else {
      switch (key) {
        // This 'text' replacement relies on i18n being updated beforehand
        case 'text':
          node[key] = text().replace(
            '{{orgName}}',
            user?.defaultOrgInfo?.name || 'LXP'
          );
          break;
        case 'routerLink':
        case 'href':
          node[key] = text()
            .replace('<analyticsUrl>', analyticsUrl)
            .replace('<learnInSSOUrl>', learnInSSOUrl)
            .replace( '<featuredPlanId>', featuredPlanId.toString());
          break;
      }
    }
  };

  return traverse(configuration, visitor);
}

/**
 * config = updateHelpMenu(config, organizationId, helpMenu)
 * Update the help menu items based on the organization's support info
 */
export function updateHelpMenu(
	configuration: LayoutConfiguration | LayoutAspect,
	organizationId: number,
	supportInfo: OrganizationSupportInfo
) {
	if (!organizationId) supportInfo = { phone: '800.311.7061' };

	const visitDGATs = (node, key) => {
		const updateByKey = (field, value, allowEmpty = false) => {
      const isVisible =  (value || allowEmpty);
      
			node.visible = isVisible;
			if (isVisible) node[field] = value || '';
		};
		if (key === 'dgat') {
			switch (node.dgat) {
				case 'global.navigation.admin.sidebar.help.knowledge-center':
				case 'global.navigation.learner.sidebar.help.knowledge-center':
					updateByKey('href', supportInfo?.helpLink);
					break;
				case 'global.navigation.admin.sidebar.help.faq':
				case 'global.navigation.learner.sidebar.help.faq':
					updateByKey('href', supportInfo?.faq);
					break;
				case 'global.navigation.admin.sidebar.help.cookie-notice':
				case 'global.navigation.learner.sidebar.help.cookie-notice':
					node.visible = supportInfo?.showCookieLink;
					break;
				case 'global.navigation.admin.sidebar.help.custom-link':
				case 'global.navigation.learner.sidebar.help.custom-link':
					updateByKey('text', supportInfo?.customText);
					updateByKey('href', supportInfo?.customLink);
					break;
				case 'global.navigation.admin.sidebar.help.support-phone':
				case 'global.navigation.learner.sidebar.help.support-phone':
					updateByKey('text', supportInfo?.phone);
					updateByKey('href', supportInfo?.phone ? `tel:${supportInfo.phone}` : '');
					break;
				case 'global.navigation.admin.sidebar.help.support-email':
				case 'global.navigation.learner.sidebar.help.support-email':
          {
            const hasEmail = supportInfo?.email && !supportInfo?.email.includes('@degreed.com');
            updateByKey('text', hasEmail ? supportInfo.email : '');
            updateByKey('href', hasEmail ? `mailto:${supportInfo.email}` : '');
          }
					break;
			}
		}
	};

	return traverse(configuration, visitDGATs);
};


/**
 * Add analytics tracking to report clicks on the navigation items
 * @returns
 */
export function addAnalyticTrackers(
  configuration: LayoutConfiguration,
  trackerService: TrackerService
): LayoutConfiguration {
  // Build analytics data for tracking
  const makeTrackData = (key: string) => {
    return {
      category: '',
      label: '',
      action: 'Global Navigation Item Clicked',
      properties: { itemClicked: key },
    };
  };
  const visitor = (node, key) => {
    switch (key) {
      case 'analytics':
        {
          const itemId = node.analytics;
          const defaultVal = node.text || '';

          // Inject callback tracking function
          node['trackEvent'] = () => {
            if (!itemId)
              console.error(
                `Missing analytics key: ${itemId} for ${defaultVal} (${node.i18n || ''})`
              );

            trackerService.trackEventData(makeTrackData(itemId));
          };
        }
        break;
    }
  };

  return traverse(configuration, visitor);
}

/**
 * Translation util that logs missing translations
 * Keys can be comma-separated to try multiple keys until a valid translation is found
 * Note: Left-most key is the most recent... right-most key is the fallback
 */
export const i18nWithLog =
  (translate: TranslateService) => (key: string, fallback: string) => {
    const allKeys = key.replace(/\s+/g, '').split(','); // This will remove all whitespace

    // Find "most recent" translation
    let value = '';
    let selectedKey = '';

    for (let i = 0; i < allKeys.length; i++) {
      if (!value && allKeys[i]) {
        selectedKey = allKeys[i];
        value = translate.instant(selectedKey) || '';
        
        // If ngx/translate returns the key, it means the translation is missing
        if (value === selectedKey) value = '';
      }

      if ( !value ) _lostKeys.track(selectedKey, fallback);  
    }


    return value || fallback;
  };

// *************************************************
// Tree Utils
// *************************************************

type LayoutNode = Record<string, any>;
type Visitor = (node: any, key: string, parent?: any) => void;

// Depth-first node tree scanning
const traverse = (node: any, visit: Visitor, parent?: LayoutNode) => {
  for (const key in node) {
    if (typeof node[key] === 'object') {
      visit(node, key, parent);
      traverse(node[key], visit, node);
    } else {
      visit(node, key, parent);
    }
  }
  return node;
};
