import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { GroupItem } from '@app/groups/group-api';
import { PopoverTriggerDirective } from '@app/shared/components/popover/popover-trigger.directive';
import { PopoverComponent } from '@app/shared/components/popover/popover.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'dgx-data-column-visibility',
  templateUrl: './data-column-visibility.component.html',
  styleUrls: ['./data-column-visibility.component.css'],
  standalone: true,
  imports: [
    PopoverComponent,
    PopoverTriggerDirective,
    CommonModule,
    TranslateModule,
  ],
})
export class DataColumnVisibilityComponent implements OnInit {
  // Bindings
  @Input() public privacy: number;
  @Input() public groups: GroupItem[];

  // Local
  public visibilities = ['Collaborators', 'Organization', 'Groups', 'Profile'];

  public ngOnInit(): void {
    this.groups =
      this.groups?.sort((a, b) => a.name.localeCompare(b.name)) || [];
  }
}
