import { NgModule } from '@angular/core';
import { MarkdownService } from './services/markdown.service';
import { MarkdownToHtmlPipe } from './pipes/markdown-to-html.pipe';
import { MarkdownToPlaintextPipe } from './pipes/strip-markdown.pipe';
import { MarkdownEditorComponent } from './markdown-editor/markdown-editor.component';
import { UploaderModule } from '@app/uploader/uploader.module';
import { TextareaService } from './services/textarea.service';
import { AutofocusDirective } from '@app/shared/directives/autofocus.directive';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ValidateFieldComponent } from '@app/shared/components/validate-field/validate-field.component';
import { CharacterLimitInputComponent } from '@app/shared/components/character-limit-input/character-limit-input.component';
import { DfIconModule } from '@lib/fresco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FinraTextareaLimitComponent } from '@app/shared/components/finra-textarea-limit/finra-textarea-limit.component';

@NgModule({
  declarations: [
    MarkdownToHtmlPipe,
    MarkdownToPlaintextPipe,
    MarkdownEditorComponent,
  ],
  imports: [
    CommonModule,
    NgbTooltipModule,
    FormsModule,
    DfIconModule,
    UploaderModule,

    //standalone components
    AutofocusDirective,
    ValidateFieldComponent,
    CharacterLimitInputComponent,
    FinraTextareaLimitComponent,
  ],
  exports: [
    MarkdownToHtmlPipe,
    MarkdownToPlaintextPipe,
    MarkdownEditorComponent,
  ],
  providers: [MarkdownService, TextareaService],
})
export class MarkdownModule {}
