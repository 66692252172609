import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { APOLLO_FEATURE_FLAG_TOKEN } from '@degreed/apollo-angular';

// components
import { ManageCredentialsComponent } from '@app/manage-credentials/components/manage-credentials/manage-credentials.component';
import { IntegrationProviderDetailsComponent } from '@app/manage-providers/integration-provider-details/integration-provider-details.component';
import { IntegrationProvidersComponent } from '@app/manage-providers/integration-providers/integration-providers.component';
import { TabNavigationItem } from '@app/navigation/navigation.model';
import { RouterComponents } from '@app/orgs/constants';
// guards
import { BrandingGuard } from '@app/orgs/services/guards/branding.guard';
import { CommunicationsGuard } from '@app/orgs/services/guards/communications.guard';
import { CredentialsGuard } from '@app/orgs/services/guards/credentials.guard';
import { FaqGuard } from '@app/orgs/services/guards/faq.guard';
import { IntegrationProvidersGuard } from '@app/orgs/services/guards/integration-providers.guard';
import { MessagingGuard } from '@app/orgs/services/guards/messaging.guard';
import { PermissionsGuard } from '@app/orgs/services/guards/permissions.guard';
// services
import { AuthService } from '@app/shared/services/auth.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TranslateFn, translateWithDefaults } from '@app/shared/utils';
import { LDFlagsService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { EditSettingsGuard } from '../../services/guards/edit-settings.guard';
import { OrgBrandingComponent } from '../org-branding/org-branding.component';
import { TabNavigationService } from './../../../navigation/components/tab-navigation/tab-navigation.service';
import { OrgPageHeaderService } from './../../services/org-page-header.service';
import { OrgFaqComponent } from './../org-faq/org-faq.component';
import { OrgHelpMenuViewComponent } from './../org-help-menu-view/org-help-menu-view.component';
import { OrgLinksTabComponent } from './../org-links/org-links-tab/org-links-tab.component';
import { OrgPermissionsTabComponent } from './../org-permissions-tab/org-permissions-tab.component';
import { OrgSettingsComponent } from './../org-settings/org-settings.component';
import { OrgViewBaseComponent } from './../org-view-base/org-view-base.component';

export enum SettingsTabComponents {
  OrgBrandingViewComponent,
  OrgHelpMenuViewComponent,
  OrgSettingsComponent,
  OrgFaqComponent,
  OrgLinksTabComponent,
  OrgPermissionsTabComponent,
  IntegrationProvidersComponent,
  IntegrationProviderDetailsComponent,
  ManageCredentialsComponent,
  ManageAttributesViewComponent,
}

@Component({
  selector: 'dgx-org-settings-nav',
  templateUrl: './org-settings-nav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgSettingsNavComponent
  extends OrgViewBaseComponent
  implements OnInit
{
  public readonly CLASS_NAME = RouterComponents.ORG_SETTINGS_NAV;

  public orgId: number;
  public showNavigation: boolean;
  public tabs: TabNavigationItem[] = [];

  private translate: TranslateFn;

  public currentRoute?: string;

  constructor(
    translateService: TranslateService,
    @Inject(APOLLO_FEATURE_FLAG_TOKEN) public useApollo: () => boolean,
    private authService: AuthService,
    private brandingGuard: BrandingGuard,
    private communicationsGuard: CommunicationsGuard,
    private credentialsGuard: CredentialsGuard,
    private editSettingsGuard: EditSettingsGuard,
    private faqGuard: FaqGuard,
    private integrationProvidersGuard: IntegrationProvidersGuard,
    private messagingGuard: MessagingGuard,
    private orgPageHeaderService: OrgPageHeaderService,
    private permissionsGuard: PermissionsGuard,
    private route: ActivatedRoute,
    private tabNavigationService: TabNavigationService,
    private trackerService: TrackerService,
    private ldFlagsService: LDFlagsService
  ) {
    super();

    this.currentRoute = this.route.snapshot.firstChild.routeConfig?.path;

    this.translate = translateWithDefaults(translateService);
  }

  public ngOnInit(): void {
    this.initializeTabs();
  }

  //TODO:
  public onActivate(
    component:
      | IntegrationProviderDetailsComponent
      | IntegrationProvidersComponent
      | ManageCredentialsComponent
      | OrgBrandingComponent
      | OrgFaqComponent
      | OrgHelpMenuViewComponent
      | OrgLinksTabComponent
      | OrgPermissionsTabComponent
      | OrgSettingsComponent
  ) {
    this.currentRoute = this.route.snapshot.firstChild.routeConfig?.path;

    const orgInfo = this.authService.authUser?.orgInfo?.find(
      (x) => x.organizationId === this.orgId
    );
    this.orgPageHeaderService.setShowPageHeader(true);
    this.showNavigation = true;
    switch (component.CLASS_NAME) {
      case SettingsTabComponents.OrgBrandingViewComponent: {
        // Don't render the legacy branding page when the new branding page is shown
        component.isHidden = this.useApollo();

        component.orgId = this.orgId;
        component.hideMasterOrgSettings =
          orgInfo.settings.hideMasterOrgSettings;
        component.canEditSettings = orgInfo.permissions.editSettings;
        component.canEditPermissions = orgInfo.permissions.editPermissions;
        this.trackEvent('Org Settings Branding Viewed');

        this.orgPageHeaderService.setShowPageHeader(false);
        break;
      }
      case SettingsTabComponents.OrgHelpMenuViewComponent:
        component.orgId = this.orgId;
        this.trackEvent('Org Settings Help Menu Viewed');

        this.orgPageHeaderService.setApolloHeader(
          'OrgSettingsNav_HelpMenu',
          'Help Menu'
        );
        break;
      case SettingsTabComponents.OrgSettingsComponent:
        const routeData = this.route.snapshot.firstChild.data;
        component.orgId = this.orgId;
        component.configurationValue = routeData.configurationValue;
        component.headingName = routeData.headingNameKey
          ? this.translate('', routeData.headingNameKey)
          : null;
        component.headingDescription = routeData.headingDescriptionKey
          ? this.translate('', routeData.headingDescriptionKey)
          : null;
        component.isMessageSettings = routeData.isMessageSettings ?? false;
        if (routeData.isMessageSettings) {
          this.trackEvent('Org Settings Messaging Viewed');
        } else {
          this.trackEvent('Org Settings Communications Viewed');
        }

        const key = routeData.isMessageSettings
          ? 'OrgSettingsNav_Messaging'
          : 'OrgSettingsNav_Communication';
        const fallback = routeData.isMessageSettings
          ? 'Messaging'
          : 'Communication';
        this.orgPageHeaderService.setApolloHeader(key, fallback);
        break;
      case SettingsTabComponents.OrgFaqComponent:
        component.orgId = this.orgId;
        component.viewOnly = false;
        this.trackEvent('Org Settings FAQ Viewed');

        this.orgPageHeaderService.setApolloHeader(
          'OrganizationsController_FAQ',
          'FAQ'
        );
        break;
      case SettingsTabComponents.OrgLinksTabComponent:
        component.orgId = this.orgId;
        component.orgName = orgInfo.name;
        if (!this.ldFlagsService.personalizedLearning) {
          this.trackerService.trackEventData({
            action: 'Org Settings Home Viewed',
          });
        }

        this.orgPageHeaderService.setPageHeader(
          'OrgSettings_HomePageTile',
          'Home',
          true
        );
        break;
      case SettingsTabComponents.OrgPermissionsTabComponent:
        component.orgId = this.orgId;
        this.trackEvent('Org Settings Permissions Viewed');

        this.orgPageHeaderService.setApolloHeader(
          'OrgSettingsNav_Permissions',
          'Permissions'
        );
        break;
      case SettingsTabComponents.IntegrationProvidersComponent:
        component.orgId = this.orgId;
        component.providerCode =
          this.route.parent.snapshot.queryParams.provider;

        this.orgPageHeaderService.setApolloHeader(
          'Core_Connected',
          'Connected'
        );
        break;
      case SettingsTabComponents.ManageCredentialsComponent:
        component.orgId = this.orgId;
        this.trackEvent('Org Settings API Viewed');

        this.orgPageHeaderService.setApolloHeader(
          'OrgManage_OauthCredentials_apiKeysTab',
          'API Keys'
        );
        break;
      case SettingsTabComponents.IntegrationProviderDetailsComponent:
        this.showNavigation = false;
        component.orgId = this.orgId;
        component.providerCode =
          this.route.firstChild.snapshot.params.providerCode;

        this.orgPageHeaderService.setShowPageHeader(false);
        break;
      default:
        break;
    }
  }

  private trackEvent(action: string): void {
    this.trackerService.trackEventData({
      action,
      properties: {},
    });
  }

  private initializeTabs(): void {
    const disableHomeAdminSettings =
      this.authService.authUser?.defaultOrgInfo?.settings
        .disableHomeAdminSettings;
    const personalizedLearning = this.ldFlagsService.personalizedLearning;

    this.tabs = this.tabNavigationService.formatTabs([
      {
        dgat: 'orgSettingsNav-810',
        sortOrder: 1,
        label: this.translate('Branding', 'OrgSettingsNav_Branding'),
        routerLink: './branding',
        isAuthorized: this.brandingGuard.hasPermission,
      },
      {
        dgat: 'orgSettingsNav-48d',
        sortOrder: 2,
        label: this.translate('Home', 'Core_Home'),
        routerLink: './home',
        isAuthorized:
          this.editSettingsGuard.hasPermission &&
          personalizedLearning &&
          this.useApollo() &&
          !disableHomeAdminSettings,
      },
      {
        dgat: 'orgSettingsNav-27a',
        sortOrder: 3,
        label: this.translate('Help Menu', 'OrgSettingsNav_HelpMenu'),
        routerLink: './helpMenu',
        isAuthorized: this.editSettingsGuard.hasPermission,
      },
      {
        dgat: 'orgSettingsNav-267',
        sortOrder: 4,
        label: this.translate('Communication', 'OrgSettingsNav_Communication'),
        routerLink: './communications',
        isAuthorized: this.communicationsGuard.hasPermission,
      },
      {
        dgat: '_OrgNav-3b0',
        sortOrder: 5,
        label: this.translate('FAQ', 'OrganizationsController_FAQ'),
        routerLink: './faq',
        isAuthorized: this.faqGuard.hasPermission,
      },
      {
        dgat: 'orgSettingsNav-48d',
        sortOrder: 6,
        label: this.translate('Home', 'Core_Home'),
        routerLink: './links',
        isAuthorized:
          this.editSettingsGuard.hasPermission &&
          (!personalizedLearning ||
            (personalizedLearning && !this.useApollo())) &&
          !disableHomeAdminSettings,
      },
      {
        dgat: 'orgSettingsNav-517',
        sortOrder: 7,
        label: this.translate('Messaging', 'OrgSettingsNav_Messaging'),
        routerLink: './messaging',
        isAuthorized: this.messagingGuard.hasPermission,
      },
      {
        dgat: 'orgSettingsNav-e59',
        sortOrder: 8,
        label: this.translate('Permissions', 'OrgSettingsNav_Permissions'),
        routerLink: './permissions',
        isAuthorized: this.permissionsGuard.hasPermission,
      },
      {
        dgat: 'orgAdvancedNav-f84',
        sortOrder: 9,
        label: this.translate('Integrations', 'User_Integrations'),
        routerLink: './integrationProviders',
        isAuthorized: this.integrationProvidersGuard.hasPermission,
      },
      {
        dgat: 'orgAdvancedNav-8fb',
        sortOrder: 10,
        label: this.translate(
          'API Keys',
          'OrgManage_OauthCredentials_apiKeysTab'
        ),
        routerLink: './manageCredentials',
        isAuthorized: this.credentialsGuard.hasPermission,
      },
    ]);
  }
}
