import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface BreadcrumbItem {
  label: string;
  link?: string;
  isActive?: boolean;
  icon?: string;
  customClass?: string;
}

@Component({
  selector: 'da-breadcrumb',
  template: `
    <nav [class]="containerClass" class="tw-flex tw-items-center tw-space-x-2 tw-text-sm">
      <ng-container *ngFor="let item of items; let last = last">
        <!-- Link Item with hover effect -->
        <a *ngIf="item.link && !item.isActive"
           [routerLink]="item.link"
           [class]="item.customClass"
           (click)="onItemClick(item)"
           class="tw-text-neutral-800 hover:tw-text-blue-600 tw-font-semibold tw-transition-colors tw-duration-200 tw-flex tw-items-center tw-gap-1 tw-cursor-pointer">
          <da-icon *ngIf="item.icon" [icon]="item.icon" class="tw-size-4"></da-icon>
          {{ item.label }}
        </a>

        <!-- Active/Current Item -->
        <span *ngIf="item.isActive"
              [class]="item.customClass"
              (click)="onItemClick(item)"
              class="tw-text-blue-800 tw-font-semibold tw-flex tw-items-center tw-gap-1 tw-cursor-pointer">
          <da-icon *ngIf="item.icon" [icon]="item.icon" class="tw-size-4"></da-icon>
          {{ item.label }}
        </span>

        <!-- Non-link Item without hover effect -->
        <span *ngIf="!item.link && !item.isActive"
              [class]="item.customClass"
              (click)="onItemClick(item)"
              class="tw-text-neutral-800 tw-font-semibold tw-flex tw-items-center tw-gap-1">
          <da-icon *ngIf="item.icon" [icon]="item.icon" class="tw-size-4"></da-icon>
          {{ item.label }}
        </span>

        <!-- Separator -->
        <span *ngIf="!last" 
              [class]="separatorClass"
              class="tw-text-neutral-600">/</span>
      </ng-container>
    </nav>
  `
})
export class BreadcrumbComponent {
  @Input() items: BreadcrumbItem[] = [];
  @Input() containerClass: string = '';
  @Input() separatorClass: string = '';

  @Output() itemClick = new EventEmitter<BreadcrumbItem>();

  onItemClick(item: BreadcrumbItem): void {
    this.itemClick.emit(item);
  }
}
