import { AuthUser } from '@app/account/account-api.model';
import { LDFlagsService } from '@dg/shared-services';

/**
 * If the org has the skills platform enabled, the user can view the skill proficiency levels on the
 * view more details and can search and save on the article/video catalog modal.
 */
export const useSkillProficiencyLevels = (
  authUser: AuthUser,
  ldFlagsService: LDFlagsService
): boolean => {
  return (
    authUser?.defaultOrgInfo?.settings.enableSkillsPlatform &&
    ldFlagsService.skillProficiencyLevelsModals
  );
};

/**
 * AI inferred skill proficiency levels will use the above useSkillProficiencyLevels and the setting AllowSkillSuggestions
 */
export const useAIInferredSkillProficiencyLevels = (
  authUser: AuthUser,
  ldFlagsService: LDFlagsService
): boolean => {
  return (
    authUser?.defaultOrgInfo?.settings.enableSkillsPlatform &&
    authUser?.defaultOrgInfo?.settings.allowSkillSuggestions &&
    ldFlagsService.skillProficiencyLevelsModals
  );
};
