import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ChangeDetectorRef,
} from '@angular/core';

/**
 * FINRA requires that we send reports about user entered data into text field: https://www.finra.org/about
 *
 * DB reports are limited to 30k characters, the user interface needs to not exceed that, even if it's an edgecase.
 * Due to the excessive size there is no UI indicator for the limit.
 */

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'textarea', // apply to ALL `textarea` inputs
  template: '',
  standalone: true,
})
export class FinraTextareaLimitComponent implements OnInit, AfterViewInit {
  private readonly finraLimit: number = 30000;
  private readonly excessiveLengthAlert: string = `textarea maxlength may not exceed ${this.finraLimit} characters: https://www.finra.org/about`;

  /** Expose nativeElement to access through @ViewChild @see MarkdownEditorComponent */
  public get nativeElement(): HTMLTextAreaElement {
    return this.elementRef.nativeElement;
  }

  constructor(
    private elementRef: ElementRef<HTMLTextAreaElement>,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    const textarea = this.elementRef.nativeElement;
    const maxlength = textarea.getAttribute('maxlength');
    const excessiveLength = +maxlength > this.finraLimit;
    if (excessiveLength) {
      console.warn(this.excessiveLengthAlert);
    }
    if (!maxlength || excessiveLength) {
      this.renderer.setAttribute(textarea, 'maxLength', `${this.finraLimit}`);
    }
  }

  public ngAfterViewInit(): void {
    this.cdr.detach();
  }
}
