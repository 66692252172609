import { Injectable } from '@angular/core';
import { ArticleMappingToAPI, ArticleModel } from '../article.model';
import { TrackerService, WebEnvironmentService } from '@dg/shared-services';
import { TagsApi } from '@app/tags/tag-api.model';
import {
  getDSSkillsAdded,
  getDSSkillsRemoved,
} from '@app/user-content/user-input-v2/utils/inferred-skills-helper';
import { getUpdatedDuration } from '@app/user-content/user-input-v2/utils/duration-helper';

@Injectable({
  providedIn: 'root',
})
export class ArticleTrackerService {
  constructor(
    private tracker: TrackerService,
    private webEnvironmentService: WebEnvironmentService
  ) {}

  public trackContentCatalogAdd(source: ArticleModel) {
    const {
      durationHours,
      durationMinutes,
      inputId,
      inputType,
      mediaLength,
      externalId,
      hostedContentDetails,
      imageUrl,
      title,
      tags,
      mediumConfidenceInferredSkills,
      highConfidenceInferredSkills,
    } = source;

    const eventDataToTrack = {
      action: 'Content Added to Catalog',
      properties: {
        contentId: inputId,
        contentType: inputType,
        dateModified: Date(),
        suggestedDuration: mediaLength,
        externalId: externalId,
        hasProficiencyLevel: !!tags?.find((tag) => !!tag.level),
        hostedType: hostedContentDetails?.hostType,
        imageUrl: imageUrl,
        title: title,
        skillsAdded: getDSSkillsAdded(tags, mediumConfidenceInferredSkills),
        skillsRemoved: getDSSkillsRemoved(tags, highConfidenceInferredSkills),
        tagsCount: tags.length,
        ...{
          updatedDuration: getUpdatedDuration(durationHours, durationMinutes),
        },
      },
    };
    this.tracker.trackEventData(eventDataToTrack);
  }

  public trackContentCatalogUpdate(source: ArticleMappingToAPI) {
    const { inputType, mediaLength, tags } = source;

    let eventDataToTrack = {
      action: 'CMS Item Updated',
      location: 'Manage Org Catalog',
      category: inputType,
      properties: {
        ...source,
        suggestedDuration: mediaLength ?? 'None',
        skillsAdded: tags.map((tag) => tag.name),
        tagsCount: tags.length,
        hasProficiencyLevel: !!tags.find((tag) => !!tag.level),
      },
    };

    this.tracker.trackEventData(eventDataToTrack);
  }

  public trackContentSkillInferred(source: ArticleModel): void {
    const appLocation = this.webEnvironmentService.analyticsAppLocation;

    const eventDataToTrack = {
      action: `${appLocation} Content Skill Inferred`,
      properties: {
        skillsAdded: getDSSkillsAdded(
          source.tags,
          source.mediumConfidenceInferredSkills
        ),
        skillsRemoved: getDSSkillsRemoved(
          source.tags,
          source.highConfidenceInferredSkills
        ),
        tagsCount: source.tags.length,
        ...{
          updatedDuration: getUpdatedDuration(
            source.durationHours,
            source.durationMinutes
          ),
        },
      },
    };

    this.tracker.trackEventData(eventDataToTrack);
  }

  public trackContentCompleted(source: ArticleMappingToAPI) {
    const {
      inputType,
      mediaLength,
      externalId,
      imageUrl,
      title,
      tags,
      sourceName,
      summary,
      entryUrl,
      inputId,
    } = source;

    const eventDataToTrack = {
      action: 'Content Completed',
      category: inputType,
      properties: {
        title: title,
        url: entryUrl,
        imageUrl: imageUrl,
        inputId: inputId,
        inputType: inputType,
        externalId: externalId,
        length: mediaLength,
        inputSource: sourceName,
        summary: summary,
        tags: tags,
        skillTagCount: tags?.length,
      },
    };
    this.tracker.trackEventData(eventDataToTrack);
  }
}
