import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { twJoin, twMerge } from '../../../utils/tw-merge';
import { useActionKey } from '../../../utils/use-action-key';
import {
  LayoutBrand,
  LayoutFeatures,
  LayoutNavigation,
  LayoutNavigationItem,
} from '../layout.model';
import { findActiveItemWithTitle, isItemActive } from '../layout.utils';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'da-header',
  template: `
    <!-- Header -->
    <header [class]="twMerge('tw-fixed tw-inset-x-0 tw-top-0', className)">
      <div
        [class]="
          twJoin(
            'tw-relative tw-z-20 tw-flex tw-h-16 tw-items-center tw-gap-4 tw-px-4 tw-transition-shadow tw-duration-200 lg:tw-pl-10 lg:tw-pr-6 rtl:lg:tw-pl-6 rtl:lg:tw-pr-10',
            'tw-bg-[var(--apollo-layout-background,white)] tw-text-[var(--apollo-layout-text)]',
            open ? 'lg:tw-shadow-sm' : 'tw-shadow-sm'
          )
        "
      >
        <!-- Header Navigation Button -->
        <div class="lg:tw-hidden">
          <button
            type="button"
            id="navigation-header-expand-button"
            class="tw-btn-medium tw-btn-icon tw-btn-secondary-filled"
            [attr.aria-expanded]="open"
            (click)="handleAutoClose(!open)"
          >
            <da-icon
              *ngIf="open"
              icon="x-mark"
              type="solid"
              [solidSize]="16"
              className="tw-size-4"
            />
            <da-icon
              *ngIf="!open"
              icon="bars-3"
              type="solid"
              [solidSize]="16"
              className="tw-size-4"
            />
            <span class="tw-sr-only">{{
              open ? 'Close Navigation' : 'Open Navigation'
            }}</span>
          </button>
        </div>

        <!-- Header Title -->
        <div
          class="tw-flex-1 tw-text-center lg:tw-pl-4 lg:tw-text-left rtl:lg:tw-pl-0 rtl:lg:tw-pr-4 rtl:lg:tw-text-right"
        >
          <h3 class="tw-text-2xl tw-font-bold">{{ title }}</h3>
        </div>

        <!-- Search Button -->
        <div
          *ngIf="features?.search.enabled && features?.search.visible"
          class="tw-hidden tw-flex-1 tw-justify-center lg:tw-flex"
        >
          <button
            *ngIf="features?.search?.enabled && features?.search?.visible"
            #searchButtonRef
            type="button"
            id="navigation-header-search-button"
            [class]="
              twMerge(
                'tw-flex tw-h-10 tw-w-80 tw-items-center tw-justify-center tw-gap-2 tw-rounded-lg tw-bg-neutral-50 tw-px-3 tw-text-left tw-text-neutral-600 tw-ring-1 tw-ring-neutral-300 rtl:tw-text-right',
                'hover:tw-bg-neutral-100 focus:tw-bg-neutral-100 focus:tw-outline-none active:tw-bg-neutral-200',
                '[&_*]:tw-pointer-events-none'
              )
            "
            (click)="handleSearch($event)"
          >
            <da-icon
              icon="magnifying-glass"
              type="solid"
              [solidSize]="20"
              className="tw-size-4 tw-shrink-0 rtl:tw-scale-x-[-1]"
            />
            <span class="tw-grow">{{ features?.search?.text }}</span>
            <kbd
              *ngIf="actionKey"
              class="tw-font-sans tw-text-xs tw-font-semibold"
            >
              <abbr [title]="actionKey[1]" class="tw-text-xs tw-no-underline">{{
                actionKey[0]
              }}</abbr>
              K
            </kbd>
          </button>
        </div>

        <!-- Header Features -->
        <div class="tw-flex tw-w-8 tw-justify-end lg:tw-w-auto lg:tw-flex-1">
          <!-- Admin Badge -->
          <div
            *ngIf="features?.admin?.enabled && features?.admin?.visible"
            [class]="
              twJoin(
                'tw-rounded-2xl tw-border tw-border-neutral-300 tw-bg-neutral-100 tw-px-2 tw-py-1 tw-text-xs tw-font-extrabold tw-uppercase tw-text-neutral-800'
              )
            "
          >
            {{ features?.admin?.text }}
          </div>

          <!-- Add Content -->
          <button
            *ngIf="
              features?.addContent?.enabled && features?.addContent?.visible
            "
            type="button"
            [class]="
              twJoin(
                'tw-btn-medium tw-btn-tertiary',
                'tw-btn-icon lg:tw-not-btn-icon',
                'tw-text-[var(--apollo-layout-highlight)]',
                'hover:tw-bg-[rgb(from_var(--apollo-layout-highlight)_r_g_b_/_15%)] hover:tw-text-[var(--apollo-layout-highlight)]',
                'focus:tw-bg-[rgb(from_var(--apollo-layout-highlight)_r_g_b_/_15%)] focus:tw-text-[var(--apollo-layout-highlight)] focus:tw-outline-none',
                'active:tw-bg-[rgb(from_var(--apollo-layout-highlight)_r_g_b_/_30%)] active:tw-text-[var(--apollo-layout-highlight)]'
              )
            "
            dgat="utilityBar-fab"
            [title]="features?.addContent?.titleText"
            (click)="handleAddContent($event)"
          >
            <da-icon
              icon="plus"
              type="solid"
              [solidSize]="16"
              className="tw-size-4"
            />
            <span class="tw-sr-only tw-whitespace-nowrap lg:tw-not-sr-only">{{
              features?.addContent?.text
            }}</span>
          </button>
        </div>
      </div>

      <!-- Header Menu -->
      <div *ngIf="open" class="tw-reset tw-relative tw-z-10">
        <div
          @OverlayTrigger
          class="tw-fixed tw-inset-x-0 tw-bottom-0 tw-top-16 tw-bg-neutral-500/75 tw-transition-opacity lg:tw-hidden"
        ></div>

        <div
          @DisclosureTrigger
          class="tw-fixed tw-inset-x-0 tw-bottom-0 tw-top-16 tw-transform tw-overflow-y-auto tw-transition lg:tw-hidden"
        >
          <div
            class="tw-relative tw-origin-top tw-bg-[var(--apollo-layout-background,white)] tw-text-[var(--apollo-layout-text)] tw-shadow-lg tw-transition"
          >
            <!-- Header Navigation -->
            <nav *ngIf="navigation">
              <ul
                class="tw-flex tw-flex-col tw-border-t tw-border-neutral-300 tw-pb-4 tw-pt-2"
              >
                <ng-container
                  *ngFor="
                    let item of navigation.top.concat(navigation.bottom ?? [])
                  "
                >
                  <li *ngIf="item.visible">
                    <!-- Nav Item -->
                    <ng-container *ngIf="item.href || item.routerLink">
                      <da-nav-item
                        [item]="item"
                        [active]="isItemActive(item)"
                        [beta]="features?.beta"
                        className="tw-px-4 tw-text-base"
                        (itemSelect)="handleAutoClose()"
                      ></da-nav-item>
                    </ng-container>

                    <!-- Nav Disclosure -->
                    <ng-container *ngIf="item.children?.length">
                      <da-nav-disclosure
                        [item]="item"
                        [beta]="features?.beta"
                        (itemSelect)="handleAutoClose()"
                      ></da-nav-disclosure>
                    </ng-container>
                  </li>
                </ng-container>
              </ul>
            </nav>

            <div
              *ngIf="features?.addContent.enabled"
              class="tw-border-t tw-border-neutral-300 tw-py-4 tw-text-center"
            >
              <a
                [href]="features?.switcher?.href"
                class="tw-btn-medium tw-btn-tertiary"
                dgat=""
                [title]="features?.switcher?.text"
              >
                <da-icon
                  icon="plus"
                  type="solid"
                  [solidSize]="16"
                  className="tw-size-4"
                />
                <span class="tw-whitespace-nowrap">{{
                  features?.switcher?.text
                }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  `,
  animations: [
    trigger('OverlayTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('500ms ease-in-out', style({ opacity: 0 })),
      ]),
    ]),
    trigger('DisclosureTrigger', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('500ms ease-in-out', style({ transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)' }),
        animate('500ms ease-in-out', style({ transform: 'translateY(-100%)' })),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() brand?: LayoutBrand;
  @Input() features?: LayoutFeatures;
  @Input() navigation?: LayoutNavigation;
  @Input() className = '';
  @Input() previewOnly = false;

  @Output() search = new EventEmitter<MouseEvent>();
  @Output() addContent = new EventEmitter<MouseEvent>();

  @ViewChild('searchButtonRef', { static: false })
  searchButtonRef!: ElementRef<HTMLButtonElement>;

  open = false;
  title = '';
  actionKey = useActionKey();
  twMerge = twMerge;
  twJoin = twJoin;
  isItemActive = (item: LayoutNavigationItem) => false;

  private subscription: Subscription;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    // This component is never destroyed.
    // Needed for future add/removeEventListeners or unsubscribe
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.watchPathname();
  }

  ngOnInit(): void {
    const { visible, enabled } = this.features?.search ?? {};
    this.activateKeydown(enabled && visible);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.navigation?.currentValue) {
      this.updateTitle();
    }

    if (changes.features?.currentValue) {
      const { visible, enabled } = changes.features?.currentValue.search ?? {};
      this.activateKeydown(enabled && visible);
    }
  }

  ngOnDestroy(): void {
    this.activateKeydown(false);
    this.subscription.unsubscribe();
  }

  private activateKeydown(active: boolean): void {
    if (active) document.addEventListener('keydown', this.handleKeyDown);
    else document.removeEventListener('keydown', this.handleKeyDown);
  }

  /**
   * Translate Cmd-K keydown to search button clicks.
   */
  handleKeyDown(e: KeyboardEvent): void {
    const search = this.features?.search || { enabled: false, visible: false };
    const enabled = search.enabled && search.visible;

    if (enabled && e.key === 'k' && (e.metaKey || e.ctrlKey)) {
      e.preventDefault();
      this.searchButtonRef.nativeElement.click();
    }
  }

  handleSearch(e: MouseEvent): void {
    const search = this.features?.search || { enabled: false, visible: false };
    const enabled = search.enabled && search.visible;

    if (enabled) {
      this.features?.search?.trackEvent?.();
      this.search.emit(e);
    }
  }

  handleAutoClose(isOpen = false): void {
    this.open = isOpen;
  }

  handleAddContent(event: MouseEvent) {
    this.addContent.emit(event);
    this.features?.addContent?.trackEvent(event);
  }

  private updateTitle(url?: string) {
    if (!this.navigation) return;

    url ||= this.router.url;

    const items = this.navigation.top.concat(this.navigation.bottom ?? []);
    const item = this.previewOnly
      ? items[0]
      : findActiveItemWithTitle(url, items);

    this.title = item?.headerTitle || '';
    this.cd.detectChanges();
  }

  /**
   * When route changes, update pathname and trigger re-renders to recalculate which item is active
   */
  private watchPathname() {
    this.isItemActive = isItemActive(this.router.url);
    this.updateTitle(this.router.url);

    this.subscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => (event as NavigationEnd).urlAfterRedirects)
      )
      .subscribe((url) => {
        this.updateTitle(url);
        this.isItemActive = isItemActive(url);
        this.cd.detectChanges();
      });
  }
}
